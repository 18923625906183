export const allBioMarkersData = {
    apob: {
        unit: "mg/dL",
        subDescription: "The most accurate indicator of long-term cardiovascular disease risk.",
        initialRangeValue: 40,
        lastRangeValue: 200,
        progressBarData: [
            {
                tagColor: "#97DFA2",
                tagLabel: "Optimal",
                currentRangeValue: 40,
                nextRangeValue: 60
            },
            {
                tagColor: "#FFCC00",
                tagLabel: "Moderate Risk",
                currentRangeValue: 60,
                nextRangeValue: 90
            },
            {
                tagColor: "#FFB524",
                tagLabel: "High Risk",
                currentRangeValue: 90,
                nextRangeValue: 130
            },
            {
                tagColor: "#FF4237",
                tagLabel: "Very High Risk",
                currentRangeValue: 130,
                nextRangeValue: 200
            },
        ]
    },
    ldlc: {
        unit: "mg/dL",
        subDescription: "Standard test for long-term risk of cardiovascular disease, used to manage and monitor treatment.",
        initialRangeValue: 5,
        lastRangeValue: 424,
        progressBarData: [
            {
                tagColor: "#97DFA2",
                tagLabel: "Optimal",
                currentRangeValue: 5,
                nextRangeValue: 70
            },
            {
                tagColor: "#FFCC00",
                tagLabel: "Moderate Risk",
                currentRangeValue: 70,
                nextRangeValue: 100
            },
            {
                tagColor: "#FFB524",
                tagLabel: "Elevated Risk",
                currentRangeValue: 100,
                nextRangeValue: 130
            },
            {
                tagColor: "#FF4237",
                tagLabel: "High Risk",
                currentRangeValue: 130,
                nextRangeValue: 180
            },
            {
                tagColor: "#B92DEB",
                tagLabel: "Very High Risk",
                currentRangeValue: 180,
                nextRangeValue: 424
            },

        ]
    },
    lipoprotein: {
        unit: "nmol/L",
        subDescription: "An ApoB particle that is a common inherited risk factor for heart disease, impacting ~20% of people worldwide.",
        initialRangeValue: 14.7,
        lastRangeValue: 233,
        progressBarData: [
            {
                tagColor: "#97DFA2",
                tagLabel: "Optimal",
                currentRangeValue: 14.7,
                nextRangeValue: 70
            },
            {
                tagColor: "#FFB524",
                tagLabel: "Elevated Risk",
                currentRangeValue: 70,
                nextRangeValue: 200
            },
            {
                tagColor: "#FF4237",
                tagLabel: "High Risk",
                currentRangeValue: 200,
                nextRangeValue: 233
            },
        ]
    },
    hdlc: {
        unit: "mg/dL",
        subDescription: "HDL may provide some protection against heart disease and is used to calculate LDL-C.",
        initialRangeValue: 11,
        lastRangeValue: 167,
        progressBarData: [
            {
                tagColor: "#FFB524",
                tagLabel: "Low",
                currentRangeValue: 11,
                nextRangeValue: 40
            },
            {
                tagColor: "#FFCC00",
                tagLabel: "Slightly Low",
                currentRangeValue: 40,
                nextRangeValue: 60
            },
            {
                tagColor: "#97DFA2",
                tagLabel: "Optimal",
                currentRangeValue: 60,
                nextRangeValue: 100
            },
            {
                tagColor: "#B92DEB",
                tagLabel: "High",
                currentRangeValue: 100,
                nextRangeValue: 167
            },
        ]
    },
    cholesterol: {
        unit: "mg/dL",
        subDescription: "Total amount of cholesterol in the blood carried by ApoB (bad) and HDL (good) particles, alike. Poor predictor of risk alone, yet used to calculate LDL-C.",
        initialRangeValue: 29,
        lastRangeValue: 790,
        progressBarData: [
            {
                tagColor: "#97DFA2",
                tagLabel: "Optimal",
                currentRangeValue: 29,
                nextRangeValue: 200
            },
            {
                tagColor: "#FFB524",
                tagLabel: "Elevated",
                currentRangeValue: 200,
                nextRangeValue: 239
            },
            {
                tagColor: "#FF4237",
                tagLabel: "High",
                currentRangeValue: 239,
                nextRangeValue: 790
            }
        ]
    },
    triglycerides: {
        unit: "mg/dL",
        subDescription: "Total triglycerides in blood. Not necessarily a risk factor, yet used to calculate LDL-C and indicate pancreatitis risk.",
        initialRangeValue: 29,
        lastRangeValue: 790,
        progressBarData: [
            {
                tagColor: "#97DFA2",
                tagLabel: "Optimal",
                currentRangeValue: 23,
                nextRangeValue: 150
            },
            {
                tagColor: "#FFB524",
                tagLabel: "Elevated",
                currentRangeValue: 150,
                nextRangeValue: 200
            },
            {
                tagColor: "#FF4237",
                tagLabel: "High",
                currentRangeValue: 200,
                nextRangeValue: 500
            },
            {
                tagColor: "#B92DEB",
                tagLabel: "Very High Risk",
                currentRangeValue: 500,
                nextRangeValue: 854
            },
        ]
    },
    hba1c: {
        unit: "%",
        subDescription: "Measure of excess blood sugar over the last ~3 months. >5.7% is pre-diabetic. >6.4% is diabetic.",
        initialRangeValue: 2.6,
        lastRangeValue: 14.5,
        progressBarData: [
            {
                tagColor: "#97DFA2",
                tagLabel: "Normal",
                currentRangeValue: 2.6,
                nextRangeValue: 5.7
            },
            {
                tagColor: "#FF4237",
                tagLabel: "Pre-Diabetes",
                currentRangeValue: 5.7,
                nextRangeValue: 6.5
            },
            {
                tagColor: "#B92DEB",
                tagLabel: "Diabetes",
                currentRangeValue: 6.5,
                nextRangeValue: 14.5
            },
        ]
    },
    egfr: {
        unit: "mg/dL",
        subDescription: "Measures how well your kidneys filter blood. Low eGFR indicates heart risk due to excess toxins left in the blood and raising blood pressure.",
        initialRangeValue: 40,
        lastRangeValue: 200,
        progressBarData: [
            {
                tagColor: "#B92DEB",
                tagLabel: "Severe loss of kidney function",
                currentRangeValue: 10,
                nextRangeValue: 30
            },
            {
                tagColor: "#FF4237",
                tagLabel: "Moderate loss of kidney function",
                currentRangeValue: 30,
                nextRangeValue: 60
            },
            {
                tagColor: "#FFCC00",
                tagLabel: "Mild loss of kidney function",
                currentRangeValue: 60,
                nextRangeValue: 90
            },
            {
                tagColor: "#97DFA2",
                tagLabel: "Optimal kidney function",
                currentRangeValue: 90,
                nextRangeValue: 180
            },
        ]
    },
}

export const userMedicalResult = {
    "message": "User result retrieved successfully",
    "data": [
        {
            "marker": "ApoB",
            "marker_slug": "apob",
            "data": {
                "label": "Moderate",
                "value": 24.0,
                "explanation_text": "This ApoB range is considered moderate risk of developing vascular disease. This is considered a relatively low risk range for those without a family history or past history of vascular disease."
            }
        },
        {
            "marker": "LDL-C",
            "marker_slug": "ldlc",
            "data": {
                "label": "Optimal",
                "value": 50.0,
                "explanation_text": "An optimal LDL-C level is considered to be the healthiest range, as it is associated with the lowest risk of vascular disease."
            }
        },
        {
            "marker": "HbA1C",
            "marker_slug": "hba1c",
            "data": {
                "label": "Normal",
                "value": 6.2,
                "explanation_text": "An optimal A1c level is considered to be the healthy range. Those on the borderline or close to 5.7 should regularly check their HbA1c to ensure they do not rise into pre-diabetic levels."
            }
        },
        {
            "marker": "HDL-C",
            "marker_slug": "hdlc",
            "data": {
                "label": "Optimal",
                "value": 60.0,
                "explanation_text": "An optimal HDL-C level may offer some protection against vascular disease"
            }
        },
        {
            "marker": "Lipoprotein",
            "marker_slug": "lipoprotein",
            "data": {
                "label": "Elevated",
                "value": 100.0,
                "explanation_text": "Elevated Lp(a) levels are considered a risk factor for vascular diseases. While there are currently few treatment options for directly lowering Lp(a), focusing on lowering LDL-C or ApoB particles in general contributes significant overall vascular disease reduction."
            }
        },
        {
            "marker": "Cholesterol",
            "marker_slug": "cholesterol",
            "data": {
                "label": "Elevated",
                "value": 205.0,
                "explanation_text": "Elevated cholesterol levels are a risk factor for vascular disease."
            }
        },
        {
            "marker": "Triglycerides",
            "marker_slug": "triglycerides",
            "data": {
                "label": "High",
                "value": 200.0,
                "explanation_text": "Your triglyceride levels are considered high. This increases your risk for heart disease and possibly pancreatitis, especially if your levels become very high. Pancreatitis is a serious and potentially chronic disease of the pancreas."
            }
        },
        {
            "marker": "eGFR",
            "marker_slug": "egfr",
            "data": {
                "label": "Mild loss",
                "value": 80.0,
                "explanation_text": "Your eGFR is slightly below the normal range, which can be normal for some people, especially older adults, but it can also indicate the beginning of kidney disease."
            }
        },
        // {
        //     "marker": "Creatinine",
        //     "data": {
        //         "label": "Optimal",
        //         "value": 1.3,
        //         "explanation_text": "While creatinine is a more crude measurement than the calculated eGFR, your levels suggest that your kidneys are functioning well, which is a strong indicator of healthy kidney activity. A creatinine level for men between 0.7-1.3 is considered normal for most people and indicates that your kidneys are healthy and working well."
        //     }
        // }
    ]
}
