import React, { useEffect, } from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { checkEmail, required } from '../../utils/antdFormValidtion';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../../config/firebase';
import { notify } from '../../utils/common';
import { pageTitles } from '../../constants/appDefaults';

const { Title, Paragraph } = Typography;

const Home = () => {
    const navigate = useNavigate();
    const onEmailSubmit = async ({ email }) => {
        try {
            sendPasswordResetEmail(auth, email)
                .then(() => {
                    notify.success("The link has been sent, please check your email to reset your password.")
                    navigate("/")
                })
                .catch((error) => {
                    console.error(error);
                });
        } catch (error) {
            console.error('error: ', error);
        }
    };

    useEffect(() => {
        document.title = pageTitles.forgotPassword;
    }, []);


    return (
        <>
            <div className="login-container">
                <Title className="section-title">Heart Health Report</Title>
                <div className="white-box">
                    <div className="white-box-content">
                        <div className="block-title-box">
                            <Title level={3} className="block-title m-0">Forgot Password</Title>
                            <Paragraph className="block-subtitle m-0">We will send the password reset link to your registered E-mail address</Paragraph>
                        </div>
                        <Form
                            name="basic"
                            onFinish={onEmailSubmit}
                            autoComplete="off"
                            layout="vertical"
                        >
                            <Form.Item
                                name="email"
                                rules={[required(), checkEmail()]}
                            >
                                <Input placeholder="Registered email" size="large" />
                            </Form.Item>
                            <div className="button-section align-center">
                                <Button type="primary" htmlType="submit" size="large" className="login-btn">Request Reset Link</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <div className="bottom-info">
                <p className="in-case-of-any-issue text-large-20">
                    <span>In case of any issue please contact us at </span>
                    <a href="mailto:help@endless.health" className="strong">help@endless.health</a>
                </p>
            </div>
        </>
    )
};
export default Home;