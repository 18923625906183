import { Col, Row, Tag } from 'antd'
import React from 'react'
import { allBioMarkersData } from '../../../constants/bioMarkersData';


function ResultSummarySection(props,props1) {
    const { userResult, dynamicText } = props;
    console.log(userResult);
    console.log(dynamicText);
    let apobBioMarker = userResult.find(res => res?.marker_slug === "apob");
    let ldlcBioMarker = userResult.find(res => res?.marker_slug === "ldlc");
    let lipoproteinBioMarker = userResult.find(res => res?.marker_slug === "lipoprotein");
    let hba1cBioMarker = userResult.find(res => res?.marker_slug === "hba1c");
    let eGFRBioMarker = userResult.find(res => res?.marker_slug === "egfr");

    const shouldShowCardioPanel = apobBioMarker && ldlcBioMarker && lipoproteinBioMarker;
    const shouldShowMetaPanel = hba1cBioMarker;
    const shouldShowKidPanel = eGFRBioMarker;

    const getBiomarkerTagColor = (marker) => {
        if (marker) {
            const progressBarData = allBioMarkersData[marker.marker_slug].progressBarData;
            let bioMarkerValue = marker.data.value;

            // Check if the value is lower than the first currentRangeValue
            if (bioMarkerValue < progressBarData[0].currentRangeValue) {
                return progressBarData[0].tagColor;
            }

            // Check if the value is higher than the last nextRangeValue
            if (bioMarkerValue > progressBarData[progressBarData.length - 1].nextRangeValue) {
                return progressBarData[progressBarData.length - 1].tagColor;
            }

            // Loop through the array and check if the value is within the range
            for (const range of progressBarData) {
                if (bioMarkerValue >= range.currentRangeValue && bioMarkerValue < range.nextRangeValue) {
                    return range.tagColor;
                }
            }
        } else {
            return "#ccc";
        }
    }

    return (

        <section className="heart-health-main-section result-summary-section">
            
            { (shouldShowCardioPanel || shouldShowKidPanel || shouldShowMetaPanel) && (
                <div className="block-header">
                    <h3 className="block-title">Now that you know about heart diseases, let’s look at your results again and understand them</h3>
                    <p className="block-description">Research shows that 80% of deaths caused by cardiovascular diseases are preventable. The first step for prevention is testing and tracking some important biomarkers:</p>
                </div> 
            )}
            
            {shouldShowCardioPanel && (
                <>
                <h4 className="content-title">Cardiovascular (heart) health</h4>
                <Row gutter={16}>
                    <Col xs={24} md={24}>
                        <div className='result-card'>
                            <h4 className="content-title">{apobBioMarker?.marker}</h4>
                            <span className='result-value'>{apobBioMarker?.data?.value} {allBioMarkersData.apob.unit}</span>
                            <Tag color={getBiomarkerTagColor(apobBioMarker)}>{apobBioMarker?.data?.label}</Tag>
                            <p className='result-content'>The most accurate test for long-term risk of cardiovascular (heart) disease</p>
                        </div>
                    </Col>
                    <Col xs={24} md={12}>
                        <div className='result-card'>
                            <h4 className="content-title">{ldlcBioMarker?.marker}</h4>
                            <span className='result-value'>{ldlcBioMarker?.data?.value} {allBioMarkersData.ldlc.unit}</span>
                            <Tag color={getBiomarkerTagColor(ldlcBioMarker)}>{ldlcBioMarker?.data?.label}</Tag>
                            <p className='result-content'>Standard test for long-term risk of cardiovascular disease, used to manage and monitor treatment.</p>
                        </div>
                    </Col>

                    <Col xs={24} md={12}>
                        <div className='result-card'>
                            <h4 className="content-title">{lipoproteinBioMarker?.marker}</h4>
                            <span className='result-value'>{lipoproteinBioMarker?.data?.value} {allBioMarkersData.lipoprotein.unit}</span>
                            <Tag color={getBiomarkerTagColor(lipoproteinBioMarker)}>{lipoproteinBioMarker?.data?.label}</Tag>
                            <p className='result-content'>An ApoB particle that is a common inherited risk factor for heart disease, impacting ~20% of people worldwide.</p>
                        </div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col md={24}>
                        <div className='result-card current-health-card'>
                            <h4 className="content-title">Your current heart health -</h4>
                            <p className='result-content'>{dynamicText?.['cardio-dynamic-text']}</p>
                        </div>
                    </Col>
                </Row>
                </>
            )}
            
            <Row gutter={32}>

                {shouldShowMetaPanel && (
                    <>
                    <Col xs={24} md={12}>
                    <h4 className="content-title">Metabolic health</h4>
                    <div className='result-card'>
                        <h4 className="content-title">{hba1cBioMarker?.marker}</h4>
                        <span className='result-value'>{hba1cBioMarker?.data?.value} {allBioMarkersData.hba1c.unit}</span>
                        <Tag color={getBiomarkerTagColor(hba1cBioMarker)}>{hba1cBioMarker?.data?.label}</Tag>
                        <p className='result-content'>Measure of average blood sugar over the last ~3 months.</p>
                    </div>
                    <div className='result-card current-health-card'>
                        <h4 className="content-title">Your HbA1C is in a {hba1cBioMarker?.data?.label} range, that means -</h4>
                        <p className='result-content'>{dynamicText?.['metabolic-dynamic-text']}</p>
                    </div>
                    </Col>
                    </>
                )}

                {shouldShowKidPanel && (
                    <>
                    <Col xs={24} md={12}>
                        <h4 className="content-title">Kidney health</h4>
                        <div className='result-card'>
                            <h4 className="content-title">{eGFRBioMarker?.marker}</h4>
                            <span className='result-value'>{eGFRBioMarker?.data?.value} {allBioMarkersData.egfr.unit}</span>
                            <Tag color={getBiomarkerTagColor(eGFRBioMarker)}>{eGFRBioMarker?.data?.label}</Tag>
                            <p className='result-content'>How well your kidneys filter your blood.</p>
                        </div>
                        <div className='result-card current-health-card'>
                            <h4 className="content-title">Your eGFR level is at {eGFRBioMarker?.data?.label},</h4>
                            <p className='result-content'>{dynamicText?.['kidney-dynamic-text']}</p>
                        </div>
                    </Col>
                    </>
                )}
            </Row>
        </section>
    )
}

export default ResultSummarySection
