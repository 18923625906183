import React from "react";
import { Button, Layout } from "antd";
import { handleUnauthorize } from "../../utils/common";
import { useSelector } from "react-redux";

const { Header } = Layout;

const AppHeader = () => {
    const currentAuthUser = useSelector(store => store.auth);

    const handleLogout = () => {
        handleUnauthorize();
    }
    return (
        <Header className="app-header">
            <div className="app-header-container">
                <div className="logo">
                    <img src="/images/logo_white.svg" alt="logo" height={30} />
                </div>
            </div>
            {
                currentAuthUser?.isAuthenticated && <Button size="large" className="mr-20" onClick={handleLogout}>Logout</Button>
            }

        </Header>
    );
}

export default AppHeader;