const theme = {
  token: {
    fontSize: 15,
    colorTextHeading: "#18322F",
    colorTextBase: "#18322F",
    colorPrimary: "#18322F",
    colorLinkActive: "#000000",
    // colorPrimaryBg: '#0E8744',
    // colorPrimaryText: '#FFFFFF',
    colorLink: '#547470',
    colorBgContainer: '#FFFFFF',
  },
};

export default theme;
