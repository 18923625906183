import React, { useEffect } from 'react';
import { Button, Result, Space } from 'antd';
import { pageTitles } from '../../constants/appDefaults';

const VerificationDone = () => {

    useEffect(() => {
        document.title = pageTitles.verificationDone;
    }, []);

    return (
        <React.Fragment>
            <div className="login-container verify-container">
                <div className="white-box verify-box">
                    <div className="white-box-content">
                        <Result
                            className="verify-result-box"
                            icon={<img src="/images/verification-heart_icon.png" alt="Verification Heart" height={152} />}
                            title="Account verified!"
                            subTitle="Your account has been successfully created"
                            extra={<Space size="large" direction="vertical" className="w-100"><Button type="primary" size="large" className="login-btn">See my health report</Button></Space>}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default VerificationDone;