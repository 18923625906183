import React from "react";
import { Layout } from "antd";
import AppHeader from "../common/Header";
import AppFooter from "../common/Footer";

const { Content } = Layout;

function ProtectedLayout({ children, showHeader = false, showFooter = false }) {
  return (
    <Layout>
      {showHeader && (
        <AppHeader />
      )}
      <Content className="main-content-after-login">{children}</Content>
      {showFooter && <AppFooter />}
    </Layout>
  );
}

export default ProtectedLayout;
