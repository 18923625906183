/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Collapse, Row, Space, Tag } from "antd";
import { useNavigate, useLocation, useHistory } from "react-router-dom";
import { DownloadOutlined, CloseOutlined } from "@ant-design/icons";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

import SmallProgressBar from "../../components/common/smallProgressBar";
import DiseaseSection from "../../components/pages/Dashboard/DiseaseSection";
import ResultSummarySection from "../../components/pages/Dashboard/ResultSummarySection";
import EndlessHealthPlugSection from "../../components/pages/Dashboard/EndlessHealthPlugSection";
import EndlessHealthCardioGuidance from "../../components/pages/Dashboard/EndlessHealthCardioGuidance";
import MedInfoSection from "../../components/pages/Dashboard/MedInfoSection";
import LifeStyleChangeAdviceSection from "../../components/pages/Dashboard/LifeStyleChangeAdviceSection";
import DisclaimerSection from "../../components/pages/Dashboard/DisclaimerSection";
import StepSection from "../../components/pages/Dashboard/StepSection";
import { pageTitles } from "../../constants/appDefaults";

import CommonBioMarker from "../../components/common/customProgressBar/CommonBioMarker";
import EgfrBar from "../../components/common/customProgressBar/Egfr";
import CreatinineMale from "../../components/common/customProgressBar/CreatinineMale";
import CreatinineFemale from "../../components/common/customProgressBar/CreatinineFemale";
import { API_ENDPOINTS } from "../../constants/apiUrl";
import axiosHelper from "../../utils/axiosHelper";
import {
  allBioMarkersData,
  userMedicalResult,
} from "../../constants/bioMarkersData";
import SourceBtnWithDetail from "../../components/common/SourceBtnWithDetail";

import { handleUnauthorize } from "../../utils/common";

import Hotjar from "@hotjar/browser";

const siteId = 3856513;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const { Panel } = Collapse;

let diseaseCardDetails = [
  {
    diseaseCardTitle: "Clots and blockage of blood flow",
    diseaseCardImage: "/images/clots-and-blockage.png",
    diseaseDescription:
      "Plaque slowly builds up in the artery wall. Once the plaque gets to a certain size, the artery can become so narrow that it blocks the blood from flowing.",
    diseaseImageGrid: [
      {
        id: 1,
        diseaseImage: "/images/heart-attack.png",
        imageContentTitle: "Heart attack",
        imageContent:
          "Sudden loss of blood flow to heart muscle, can cause sudden death",
      },
      {
        id: 2,
        diseaseImage: "/images/heart-failure.png",
        imageContentTitle: "Heart failure",
        imageContent: "Heart not pumping enough blood to the body",
      },
      {
        id: 3,
        diseaseImage: "/images/stroke.png",
        imageContentTitle: "Stroke",
        imageContent: "Lack of oxygen to part of the brain",
      },
    ],
  },
  {
    diseaseCardTitle: "Weakening and rupture of artery walls",
    diseaseCardImage: "/images/weakening-and-rupture.png",
    diseaseDescription:
      "Plaque weakens artery walls and blocks blood flow until the arteries burst causing brain-bleeds or internal bleeding.",
    diseaseImageGrid: [
      {
        id: 1,
        diseaseImage: "/images/aneurysm.png",
        imageContentTitle: "Aneurysm",
        imageContent:
          "Abnormal bulging and ballooning of a weekend artery, that can burst and stop blood flow to vital organs",
      },
    ],
  },
  {
    diseaseCardTitle: "Blockage of blood flow to heart, lungs, limbs or brain",
    diseaseCardImage: "/images/blockage-to-heart.png",
    diseaseDescription:
      "Clots breakoff and get stuck in thinner arteries that are connected to the heart, lungs, limbs or brain, blocking the blood flow to these organs",
    diseaseImageGrid: [
      {
        id: 1,
        diseaseImage: "/images/embolism.png",
        imageContentTitle: "Embolism",
        imageContent: "Clots and blockage in arteries",
      },
    ],
  },
];

let interactiveHearthGraphRightBarContents = [
  {
    uid: "artery",
    titleForRightBar: "The first thing you need to know",
    buttonLabelForRightBar: "Artery",
    colorCode: "#E4382E",
    hasModalContent: true,
    modalContent: () => (
      <>
        <h3>
          Arteries - tubes that carry oxygen-rich blood from heart to all parts
          of the body.
        </h3>
        <p>
          Artery: tubes that are part of our Circulatory System that carry
          oxygen-rich blood from the heart to all parts of the body.
        </p>
        <p>
          A healthy artery is one which can smoothly and efficiently carry
          blood, while an unhealthy artery has blockage within it that disrupts
          the flow of blood.
        </p>
        <p className="bottom-text">
          Blocked arteries are the root cause of all cardiovascular diseases
        </p>
      </>
    ),
  },
  {
    uid: "plaque",
    titleForRightBar: "The root cause of heart diseases ",
    buttonLabelForRightBar: "Plaque",
    colorCode: "#FF9301",
    hasModalContent: true,
    modalContent: () => (
      <>
        <h3>
          Plaque build-up is what disrupts blood flow in the arteries and causes
          heart diseases.
        </h3>
        <p>
          The buildup is caused by the deposition of{" "}
          <span className="text-green">unwanted substances*</span> in the inner
          lining of artery walls. It is a slow and progressive process that may
          start as early as childhood. At times it can progress rapidly.
          However, there are several lifestyle interventions and medicines that
          can also reverse the process.
        </p>
        <p className="bottom-text">
          Unwanted substances - fatty substances, cholesterol, cellular waste
          products, calcium, and fibrin
        </p>
      </>
    ),
  },
  {
    uid: "cholesterol",
    titleForRightBar: "Let's get this right",
    buttonLabelForRightBar: "Cholesterol",
    colorCode: "#FFC600",
    hasModalContent: true,
    modalContent: () => (
      <>
        <h3>Cholesterol</h3>
        <p>
          Cholesterol helps make cells in the body have their shapes and
          functions and is typically completely healthy. They are waxy and
          repelled from blood so they have to be shuttled in perfectly healthy
          ways by small particles that do not get stuck in artery walls. When
          they do get stuck outside the blood stream in artery walls via ApoB
          particles, the process is hard to reverse once it has begun, but it
          can be slowed with healthy lifestyles and medical interventions.
        </p>
      </>
    ),
  },
  {
    uid: "lipoproteins",
    titleForRightBar: "",
    buttonLabelForRightBar: "Lipoprotein",
    colorCode: "#18322F",
    hasModalContent: true,
    modalContent: () => (
      <>
        <h3>Lipoproteins - The cargo vehicles of the bloodstream</h3>
        <p>
          Lipoproteins are important shuttling particles in the blood that carry
          essential cargo, cholesterol and triglycerides, all over the body.
          They can be healthy or dangerous depending on their size and the
          proteins on their surface like ApoB, which make them pass through and
          stick outside the inner lining of the artery wall.
        </p>
      </>
    ),
  },
  {
    uid: "apob",
    titleForRightBar: "How we test our risk of heart disease",
    buttonLabelForRightBar: "ApoB",
    colorCode: "#18322F",
    hasModalContent: true,
    modalContent: () => (
      <>
        <h3>ApoB - a protein present in all plaque-causing lipoproteins. </h3>
        <p>
          An ApoB test is a highly accurate indication of the plaque buildup,
          and hence the risk of heart disease. Lower levels are better. Thus
          regularly testing ApoB particle levels and using them as a measure of
          lifestyle or treatment success yields the best prevention of diseases
          according to the American Association of Clinical Chemistry, the
          European Federation of Laboratory Medicine, the European
          Atherosclerosis Society, and the European Society of Cardiology.
        </p>
      </>
    ),
  },
  {
    uid: "ldl",
    titleForRightBar: "",
    buttonLabelForRightBar: "LDL",
    colorCode: "#84BFF6",
    hasModalContent: true,
    modalContent: () => (
      <>
        <h3>
          LDL - A deadly ApoB particle that deposits cholesterol in the wrong
          places
        </h3>
        <p>
          Low density lipoproteins (LDL), which are a common type of ApoB
          particle with high amounts of cholesterol inside are often the culprit
          for plaque formation. They drop quickly with a low saturated fat and
          processed foods diet and with statin or other therapies. Thus, they
          are often the medical targets for intervention to stop plaque
          formation and heart disease.{" "}
        </p>
      </>
    ),
  },
  {
    uid: "lpa",
    titleForRightBar: "",
    buttonLabelForRightBar: "Lp(a)",
    colorCode: "#CE08FF",
    hasModalContent: true,
    modalContent: () => (
      <>
        <h3>
          Lp(a) - An inherited ApoB particle, spreading risk throughout some
          families
        </h3>
        <p>
          Lipoprotein a (Lp(a)) is another common type of ApoB particle with
          high amounts of cholesterol inside, but is not as atherogenic as
          LDL-C. Unfortunately, it does not respond to known dietary changes or
          currently available medications. Yet, it is highly elevated in 5-20%
          of the US population, meaning those who have elevated risk should take
          steps to lower their ApoB particles overall, perhaps focusing on LDL-C
          for now.
        </p>
      </>
    ),
  },
  {
    uid: "other_plaque_lipoproteins",
    titleForRightBar: "",
    buttonLabelForRightBar: "Other plaque causing Lipoproteins",
    colorCode: "#219039",
    hasModalContent: true,
    modalContent: () => (
      <>
        <h3>Other ApoB particles</h3>
        <p>
          Other ApoB lipoproteins like very low density lipoproteins (VLDL) or
          IDL also cause plaques, but are not tested in the common LDL-C
          measurement. These particles tend to be larger and can deposit large
          amounts of cholesterol and they also carry large amounts of
          triglycerides, which store and provide energy from sugar and fat
          digested. Lowering these particles through diet can mean reducing
          sugar intake to drop triglyceride formation.{" "}
        </p>
      </>
    ),
  },
];

let healthPlanCardDetails = [
  {
    healthPlanCardTitle: "TEST",
    healthPlanCardImage: "/images/test-image.png",
    healthPlanDescription:
      "Regular testing is the first step! Keep in check with at home testing.",
    ctaButtonText: "Learn about at home testing",
  },
  {
    healthPlanCardTitle: "TRAIN",
    healthPlanCardImage: "/images/train-image.png",
    healthPlanDescription:
      "Tracking long term changes helps build effective treatment plan",
    ctaButtonText: "How we customize your plan",
  },
  {
    healthPlanCardTitle: "TRACK",
    healthPlanCardImage: "/images/track-image.png",
    healthPlanDescription:
      "You know what to do, but doing it is difficult. Small steps go a long way.",
    ctaButtonText: "How long term tracking benefits",
  },
];

const cardiovascularBioMarkers = [
  "apob",
  "ldlc",
  "lipoprotein",
  "hdlc",
  "cholesterol",
  "triglycerides",
];
const metabolicBioMarkers = ["hba1c"];
const kidneyBioMarkers = ["egfr", "creatinine"];

function Dashboard() {
  const navigate = useNavigate();
  const currentAuthUser = useSelector((store) => store.auth);
  const [activeCardioBioMarkersList, setActiveCardioBioMarkersList] = useState(
    []
  );
  const [activeMetabolicBioMarkersList, setActiveMetabolicBioMarkersList] =
    useState([]);
  const [activeKidneyBioMarkersList, setActiveKidneyBioMarkersList] = useState(
    []
  );
  const [heartHealthModalContent, setHeartHealthModalContent] = useState("");
  const [heartHealthInteractiveUid, setHeartHealthInteractiveUid] = useState(
    "default_heart_health"
  );
  const [heartHealthInteractiveColorCode, setHeartHealthInteractiveColorCode] =
    useState("#FFFFFF");
  const location = useLocation();
  const userInfo = location.state;
  const finalFormattedDate = null;
  if (userInfo && userInfo.user_orders && userInfo.user_orders.length > 0) {
    const dateObject = new Date(userInfo.user_orders[0].created_at);
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formatter = new Intl.DateTimeFormat("en-US", options);

    const getDayWithSuffix = (day) =>
      day >= 11 && day <= 13
        ? `${day}th`
        : `${day}${[, "st", "nd", "rd"][day % 10] || "th"}`;

    const finalFormattedDate = `${formatter
      .format(dateObject)
      .replace(/(\d{1,2})/, getDayWithSuffix(dateObject.getDate()))}`;
    console.log(finalFormattedDate);
  } else {
    console.log("userInfo.user_orders is null or empty");
  }

  useEffect(() => {
    if (!currentAuthUser.user.emailVerified) {
      navigate("/verification-pending");
    }
  }, [currentAuthUser.user.emailVerified]);

  useEffect(() => {
    document.addEventListener("click", closeHeartHealthModalOnOutsideClick);
    return () => {
      document.removeEventListener(
        "click",
        closeHeartHealthModalOnOutsideClick
      );
    };
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", (event) => {});
    return () => {
      handleUnauthorize();
    };
  }, []);

  const [userResult, setUserResult] = useState([]);
  const [dynamicText, setDynamicText] = useState([]);

  let hba1cBioMarker = userResult.find((res) => res?.marker_slug === "hba1c");
  let eGFRBioMarker = userResult.find((res) => res?.marker_slug === "egfr");

  const handleActiveCardioBioMarkersChange = (keys) => {
    setActiveCardioBioMarkersList(keys);
  };
  const handleActiveMetabolicBioMarkersChange = (keys) => {
    setActiveMetabolicBioMarkersList(keys);
  };

  const handleActiveKidneyBioMarkersChange = (keys) => {
    setActiveKidneyBioMarkersList(keys);
  };

  const handleCardClick = (card) => {
    if (heartHealthInteractiveUid === card.uid) {
      setHeartHealthModalContent("");
      setHeartHealthInteractiveUid("default_heart_health");
      setHeartHealthInteractiveColorCode("#FFFFFF");
    } else {
      setHeartHealthModalContent(card.modalContent());
      setHeartHealthInteractiveUid(card.uid);
      setHeartHealthInteractiveColorCode(card.colorCode);
    }
  };

  const handleHeartHealthModalClose = (card) => {
    setHeartHealthModalContent("");
    setHeartHealthInteractiveUid("default_heart_health");
  };

  const downloadPdfDocument = () => {
    const input = document.querySelector("#dashboardContainer");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save(`downloadFileName.pdf`);
    });
  };

  const getUserResults = async (email) => {
    try {
      const response = await axiosHelper.get(
        `${API_ENDPOINTS.getUserResults}?email=${email}`
      );
      setUserResult(response.data);
      setDynamicText(response.dynamic_text);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    if (currentAuthUser) {
      getUserResults(currentAuthUser?.user?.email);
    }
    // setUserResult(userMedicalResult.data);
    document.title = pageTitles.dashboard;
  }, [currentAuthUser]);

  const closeHeartHealthModalOnOutsideClick = (event) => {
    // console.log('event: ', event.target);
    if (
      event.target.closest(".heart-health-modal") ||
      event.target.closest(".heart-biomarker")
    ) {
    } else {
      handleHeartHealthModalClose();
    }
  };

  const getBiomarkerTagColor = (marker) => {
    if (marker) {
      const progressBarData =
        allBioMarkersData[marker.marker_slug].progressBarData;
      let bioMarkerValue = marker.data.value;

      // Check if the value is lower than the first currentRangeValue
      if (bioMarkerValue < progressBarData[0].currentRangeValue) {
        return progressBarData[0].tagColor;
      }

      // Check if the value is higher than the last nextRangeValue
      if (
        bioMarkerValue >
        progressBarData[progressBarData.length - 1].nextRangeValue
      ) {
        return progressBarData[progressBarData.length - 1].tagColor;
      }

      // Loop through the array and check if the value is within the range
      for (const range of progressBarData) {
        if (
          bioMarkerValue >= range.currentRangeValue &&
          bioMarkerValue < range.nextRangeValue
        ) {
          return range.tagColor;
        }
      }
    } else {
      return "#ccc";
    }
  };

  return (
    <div className="container" id="dashboardContainer">
      <div className="dashboard-wrapper" id="dashboardWrapper">
        <div className="vascular-wrap">
          <h3>
            Vascular (heart) diseases are the #1 cause of death globally, by
            far.
          </h3>
          <h3 className="yet-para">
            Yet at least 80% of cases are preventable.
          </h3>
          <p>Endless Health is committed to prevent heart diseases through:</p>
          <ul className="eh-list">
            <li>Accessible testing with interpretable result</li>
            <li>Science-backed lifestyle suggestions</li>
            <li>Personalized, actionable insights</li>
          </ul>
        </div>
        <StepSection userInfo={userInfo} />
        <br />
        <br />
        <div className="page-header">
          <div className="page-header-left">
            <h1 className="page-title">
              Hi {currentAuthUser?.user?.firstName}{" "}
              {currentAuthUser?.user?.lastName}
            </h1>
            {finalFormattedDate !== null && (
              <p className="page-description">
                Here's your result from {finalFormattedDate}:
              </p>
            )}
          </div>
          {/* <div className='page-header-right'>
                        <Button icon={<DownloadOutlined />} onClick={downloadPdfDocument}>
                            Download PDF
                        </Button>
                    </div> */}
        </div>
        <section className="dashboard-section">
          <div className="block-header">
            <h3 className="block-title">Cardiovascular (heart) health</h3>
            <p className="block-description">
              The most important test to predict long-term risk of plaques that
              lead to most heart disease is ApoB. We also test its primary
              components: LDL-C and Lipoprotein (a). Other test help to
              calculate LDL-C and have little predictive value.
            </p>
          </div>
          <Collapse
            className="health-collapse"
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <span>
                {isActive ? (
                  <img src="/images/chevron-up.png" alt="chevron-up" />
                ) : (
                  <img src="/images/chevron-down.png" alt="chevron-down" />
                )}
              </span>
            )}
            // defaultActiveKey={['1']}
            onChange={handleActiveCardioBioMarkersChange}
            // defaultActiveKey={['1', '2', '3', '4', '5', '6',]}
          >
            {userResult.map((marker, index) => {
              if (cardiovascularBioMarkers.includes(marker.marker_slug)) {
                return (
                  <Panel
                    header={
                      <div>
                        <h4>{marker.marker}</h4>
                        <p>
                          {allBioMarkersData[marker.marker_slug].subDescription}
                        </p>
                      </div>
                    }
                    extra={
                      <div>
                        <Space>
                          <h3>
                            {marker.data.value}{" "}
                            {allBioMarkersData[marker.marker_slug].unit}
                          </h3>
                          <Tag
                            color={getBiomarkerTagColor(marker)}
                            className="bigger"
                          >
                            {marker.data.label}
                          </Tag>
                        </Space>
                        {!activeCardioBioMarkersList.includes(`${index + 1}`) &&
                        marker.marker_slug ? (
                          <SmallProgressBar
                            bioMarkerValue={marker.data.value}
                            progressBarData={
                              allBioMarkersData[marker.marker_slug]
                                .progressBarData
                            }
                            initialRangeValue={
                              allBioMarkersData[marker.marker_slug]
                                .initialRangeValue
                            }
                            lastRangeValue={
                              allBioMarkersData[marker.marker_slug]
                                .lastRangeValue
                            }
                          />
                        ) : null}
                      </div>
                    }
                    key={index + 1}
                  >
                    <CommonBioMarker
                      bioMarkerValue={marker.data.value}
                      progressBarData={
                        allBioMarkersData[marker.marker_slug].progressBarData
                      }
                      initialRangeValue={
                        allBioMarkersData[marker.marker_slug].initialRangeValue
                      }
                      lastRangeValue={
                        allBioMarkersData[marker.marker_slug].lastRangeValue
                      }
                    />
                    <h4>
                      What does it mean to be in {marker.data.label} range?
                    </h4>
                    <p>{marker.data.explanation_text}</p>
                  </Panel>
                );
              }
            })}
          </Collapse>
        </section>
        <section className="dashboard-section">
          {hba1cBioMarker && (
            <div className="block-header">
              <h3 className="block-title">Metabolic health</h3>
              <p className="block-description">
                HbA1C is an indicator of blood sugar level. If it is higher than
                5.7, it means that blood glucose is not being effectively
                regulated and can lead to diabetes. Diabetes is a risk to heart
                health.
              </p>
            </div>
          )}
          <Collapse
            className="health-collapse"
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <span>
                {isActive ? (
                  <img src="/images/chevron-up.png" alt="chevron-up" />
                ) : (
                  <img src="/images/chevron-down.png" alt="chevron-down" />
                )}
              </span>
            )}
            onChange={handleActiveMetabolicBioMarkersChange}
          >
            {userResult.map((marker, index) => {
              if (metabolicBioMarkers.includes(marker.marker_slug)) {
                return (
                  <Panel
                    header={
                      <div>
                        <h4>{marker.marker}</h4>
                        <p>
                          {allBioMarkersData[marker.marker_slug].subDescription}
                        </p>
                      </div>
                    }
                    extra={
                      <div>
                        <Space>
                          <h3>
                            {marker.data.value}{" "}
                            {allBioMarkersData[marker.marker_slug].unit}
                          </h3>
                          <Tag
                            color={getBiomarkerTagColor(marker)}
                            className="bigger"
                          >
                            {marker.data.label}
                          </Tag>
                        </Space>
                        {!activeMetabolicBioMarkersList.includes(
                          `${index + 1}`
                        ) && marker.marker_slug ? (
                          <SmallProgressBar
                            bioMarkerValue={marker.data.value}
                            progressBarData={
                              allBioMarkersData[marker.marker_slug]
                                .progressBarData
                            }
                            initialRangeValue={
                              allBioMarkersData[marker.marker_slug]
                                .initialRangeValue
                            }
                            lastRangeValue={
                              allBioMarkersData[marker.marker_slug]
                                .lastRangeValue
                            }
                          />
                        ) : null}
                      </div>
                    }
                    key={index + 1}
                  >
                    <CommonBioMarker
                      bioMarkerValue={marker.data.value}
                      progressBarData={
                        allBioMarkersData[marker.marker_slug].progressBarData
                      }
                      initialRangeValue={
                        allBioMarkersData[marker.marker_slug].initialRangeValue
                      }
                      lastRangeValue={
                        allBioMarkersData[marker.marker_slug].lastRangeValue
                      }
                    />
                    <h4>
                      What does it mean to be in {marker.data.label} range?
                    </h4>
                    <p>{marker.data.explanation_text}</p>
                  </Panel>
                );
              }
            })}
          </Collapse>
        </section>
        <section className="dashboard-section">
          {eGFRBioMarker && (
            <div className="block-header">
              <h3 className="block-title">Kidney Health</h3>
              <p className="block-description">
                The kidneys clean the blood, removing waste products and extra
                water. Any abnormality in this kidney function is directly
                linked to having a risk of heart disease. At the same time,
                heart disease is also a risk for kidney disease.
              </p>
            </div>
          )}
          <Collapse
            className="health-collapse"
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <span>
                {isActive ? (
                  <img src="/images/chevron-up.png" alt="chevron-up" />
                ) : (
                  <img src="/images/chevron-down.png" alt="chevron-down" />
                )}
              </span>
            )}
            onChange={handleActiveKidneyBioMarkersChange}
          >
            {userResult.map((marker, index) => {
              if (
                kidneyBioMarkers.includes(marker.marker_slug) &&
                marker.marker_slug === "egfr"
              ) {
                return (
                  <Panel
                    header={
                      <div>
                        <h4>{marker.marker}</h4>
                        <p>
                          {allBioMarkersData[marker.marker_slug].subDescription}
                        </p>
                      </div>
                    }
                    extra={
                      <div>
                        <Space>
                          <h3>
                            {marker.data.value}{" "}
                            {allBioMarkersData[marker.marker_slug].unit}
                          </h3>
                          <Tag
                            color={getBiomarkerTagColor(marker)}
                            className="bigger"
                          >
                            {marker.data.label}
                          </Tag>
                        </Space>
                        {!activeKidneyBioMarkersList.includes(`${index + 1}`) &&
                        marker.marker_slug ? (
                          <SmallProgressBar
                            bioMarkerValue={marker.data.value}
                            markerSlug={marker.marker_slug}
                            progressBarData={
                              allBioMarkersData[marker.marker_slug]
                                .progressBarData
                            }
                            initialRangeValue={
                              allBioMarkersData[marker.marker_slug]
                                .initialRangeValue
                            }
                            lastRangeValue={
                              allBioMarkersData[marker.marker_slug]
                                .lastRangeValue
                            }
                          />
                        ) : null}
                      </div>
                    }
                    key="1"
                  >
                    <EgfrBar bioMarkerValue={marker.data.value} />
                    <h4>
                      What does it mean to be in {marker.data.label} range?
                    </h4>
                    <p>{marker.data.explanation_text}</p>
                  </Panel>
                );
              }
            })}

            {/* <Panel
                            header={(<div><h4>Creatinine (non-fasting)</h4><p>Creatinine is used to calculate eGFR and is a measure of remnant metabolites in blood stream.</p></div>)}
                            extra={(<div><Space><h3>20 mg/dL</h3> <Tag color="green" className="bigger">Optimal</Tag></Space>
                                {!activeKidneyBioMarkersList.includes("2") && <SmallProgressBar status="optimal" />}
                            </div>)}
                            key="2"
                        >

                            <CreatinineMale bioMarkerValue="20" />
                            <CreatinineFemale bioMarkerValue="20" />
                            <h4>What does it mean to be in this range?</h4>
                            <p>This range is considered optimal and is associated with the lowest risk of developing heart disease. People within this range are generally at the lowest risk for atherosclerosis, which is the buildup of fatty deposits (plaque) in the arteries.</p>
                        </Panel> */}
          </Collapse>
        </section>
        <section className="faq-section">
          <div className="block-header">
            <h3 className="block-title">FAQ</h3>
            <p className="block-description">
              Can't find answer? Contact us at{" "}
              <a href="mailto: help@endless.health">help@endless.health</a>
            </p>
          </div>
          <Collapse
            className="faq-accordion"
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <span>
                {isActive ? (
                  <img src="/images/chevron-up.png" alt="chevron-up" />
                ) : (
                  <img src="/images/chevron-down.png" alt="chevron-down" />
                )}
              </span>
            )}
            accordion
          >
            <Panel
              header="How accurate is the finger prick test compare to Venous blood?"
              key="1"
            >
              <p>
                We validate all of our assays alongside venous blood draw
                samples from the same patients and they are certified by the The
                Centers for Medicare & Medicaid Services (CMS) and The College
                of American Pathologists (CAP) who provide regular independently
                validated external samples to ensure assay and collection method
                validity. Every test has over 95% correlation between finger
                prick and venous blood. Low bias within acceptable bounds for
                each assay was established when compared to other labs
                performing the same assays on venous samples. Test accuracy and
                sample durability has been established up to 14 days within
                provided storage bags for dried blood spot samples and
                throughout the transport period for wet blood samples.
                <SourceBtnWithDetail detailText="" />
              </p>
            </Panel>
            <Panel
              header="How does fasting or not fasting influence my test result?"
              key="2"
            >
              <p>
                While the above biomarkers remain good resources for predicting
                cardiovascular disease risk, the predictive power of
                Triglyceride, Total Cholesterol, LDL-C, Creatinine, and eGFR
                measurements are decreased when non-fasting.
                <SourceBtnWithDetail detailText="" />
              </p>
              <p>
                Maximal mean changes for random, non-fasting versus fasting
                levels are +26 mg/dl for triglycerides, −8 mg/dl for total
                cholesterol, −8 mg/dl for low-density lipoprotein cholesterol,
                +8 mg/dl for remnant cholesterol, and −8 mg/dl for
                lipoprotein(a), apolipoprotein B, and high-density lipoprotein
                cholesterol are largely unaffected.
                <SourceBtnWithDetail detailText="" />
              </p>
            </Panel>
          </Collapse>
        </section>

        <EndlessHealthCardioGuidance />

        <section className="heart-health-main-section">
          <div className="block-header">
            <h3 className="block-title">
              What does heart health mean? What are the risks?
            </h3>
            <p className="block-description">
              The heart is a vital organ that pumps blood to deliver oxygen
              throughout the body. Arteries, which are tube-like vessels help
              carry the blood to all parts of the body. There is a risk of{" "}
              <a href="#">
                heart diseases and vascular diseases throughout the body
              </a>{" "}
              when arteries are blocked by the accumulation of{" "}
              <a href="#">plaque</a>, preventing blood flow. The health of the
              arteries is the major determinant of what is commonly referred to
              as heart health.
            </p>
          </div>
          <div>
            <div className="heart-health-main-section-large-screen">
              <Row gutter={32}>
                <Col span={8}>
                  <div className="risk-card">
                    <div className="img">
                      <img src="/images/clear_artery.png" alt="Clear Artery" />
                    </div>
                    <p className="title">
                      <strong>Healthy vascular system</strong>
                    </p>
                    <p className="title">
                      (ApoB, LDL and Lp(a) is in a normal range)
                    </p>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="risk-card">
                    <div className="img">
                      <img
                        src="/images/artery_with_plaque.png"
                        alt="Artery With Plaque"
                      />
                    </div>
                    <p className="title">
                      <strong>Vascular system at risk</strong>
                    </p>
                    <p className="title">
                      (Elevated ApoB, LDL or Lp(a) for 5-10 years)
                    </p>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="risk-card">
                    <div className="img">
                      <img
                        src="/images/heart_muscle_damage.png"
                        alt="Heart Muscle Damage"
                      />
                    </div>
                    <p className="title">
                      <strong>Vascular system at high risk</strong>
                    </p>
                    <p className="title">
                      (Elevated ApoB, LDL or Lp(a) for 15-20 years)
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            {/* Small screen layout */}
            <div className="heart-health-main-section-small-screen">
              <Row gutter={32}>
                <Col span={24}>
                  <div className="risk-card">
                    <div className="img">
                      <img
                        src="/images/clear_artery_small.png"
                        alt="Clear Artery"
                      />
                    </div>
                    <p className="title">
                      <strong>Healthy vascular system</strong>
                      (ApoB, LDL and Lp(a) is in a normal range)
                    </p>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="risk-card">
                    <div className="img">
                      <img
                        src="/images/artery_with_plaque_small.png"
                        alt="Artery With Plaque"
                      />
                    </div>
                    <p className="title">
                      <strong>Vascular system at risk</strong>
                      (Elevated ApoB, LDL or Lp(a) for 5-10 years)
                    </p>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="risk-card">
                    <div className="img">
                      <img
                        src="/images/heart_muscle_damage_small.png"
                        alt="Heart Muscle Damage"
                      />
                    </div>
                    <p className="title">
                      <strong>Vascular system at high risk</strong> (Elevated
                      ApoB, LDL or Lp(a) for 15-20 years)
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
        <section className="heart-health-main-section">
          <div className="block-header">
            <h3 className="block-title">
              Heart diseases seem sudden, but they develop gradually from
              teenage years. They are externally invisible to us.
            </h3>
            <p className="block-description">
              Heart diseases are caused by plaques that grow slowly throughout
              our lifetime. The progression of the disease is invisible to us,
              but we can halt it starting today. Here we show how the build-up
              of ApoB particles in the arteries leads to heart diseases.
            </p>
            <br />
            <p className="block-description">
              ApoB particles are those that lodge themselves in artery walls and
              restrict blood flow. They include LDL, which contains the
              cholesterol commonly referred to as &lsquo;bad cholesterol&rsquo;,
              and Lipoprotein (a), which is a common familial (inherited) marker
              that is a risk factor for 20% of the global population.
              <SourceBtnWithDetail detailText="Varvel, S., McConnell, J. P. & Tsimikas, S. Prevalence of Elevated Lp(a) Mass Levels and Patient Thresholds in 532 359 Patients in the United States. Arterioscler. Thromb. Vasc. Biol. 36, 2239–2245 (2016)" />
            </p>
          </div>
          <div className="heart-health-measures-section">
            <div className="left-side">
              <img
                src={`/images/selected-${heartHealthInteractiveUid}.svg`}
                alt="Heart Health 1"
              />
              <div
                className={"heart-health-modal".concat(
                  !heartHealthModalContent ? " d-none" : ""
                )}
                style={{ borderColor: heartHealthInteractiveColorCode }}
              >
                <Button
                  className="close-btn"
                  onClick={handleHeartHealthModalClose}
                >
                  <CloseOutlined />
                </Button>
                <div className="heart-health-modal-body">
                  {heartHealthModalContent}
                </div>
              </div>
            </div>
            <div className="right-side">
              {interactiveHearthGraphRightBarContents.map((content) => {
                return (
                  <>
                    <p className="title">{content.titleForRightBar}</p>
                    <a
                      className={"heart-biomarker icon-text-card".concat(
                        heartHealthInteractiveUid === content.uid
                          ? " active"
                          : ""
                      )}
                      onClick={() => {
                        if (content.hasModalContent) {
                          handleCardClick(content);
                        }
                      }}
                      style={{
                        borderColor:
                          heartHealthInteractiveUid === content.uid
                            ? heartHealthInteractiveColorCode
                            : "",
                      }}
                    >
                      <span className="icon">
                        <img
                          src={`/images/${content.uid}.svg`}
                          alt={content.buttonLabelForRightBar}
                        />
                      </span>
                      <span className="name">
                        {content.buttonLabelForRightBar}
                      </span>
                    </a>
                  </>
                );
              })}
            </div>
          </div>
          <div className="heart-health-measures-section-small">
            <div className="left-side">
              <img
                src={`/images/selected-${heartHealthInteractiveUid}.svg`}
                alt="Heart Health 1"
              />
              <div
                className={"heart-health-modal".concat(
                  !heartHealthModalContent ? " d-none" : ""
                )}
                style={{ borderColor: heartHealthInteractiveColorCode }}
              >
                <Button
                  className="close-btn"
                  onClick={handleHeartHealthModalClose}
                >
                  <CloseOutlined />
                </Button>
                <div className="heart-health-modal-body">
                  {heartHealthModalContent}
                </div>
              </div>
            </div>
            <div className="right-side">
              {interactiveHearthGraphRightBarContents.map((content) => {
                return (
                  <>
                    <p className="title">{content.titleForRightBar}</p>
                    <a
                      className={"heart-biomarker icon-text-card".concat(
                        heartHealthInteractiveUid === content.uid
                          ? " active"
                          : ""
                      )}
                      onClick={() => {
                        if (content.hasModalContent) {
                          handleCardClick(content);
                        }
                      }}
                      style={{
                        borderColor:
                          heartHealthInteractiveUid === content.uid
                            ? heartHealthInteractiveColorCode
                            : "",
                      }}
                    >
                      <span className="icon">
                        <img
                          src={`/images/${content.uid}.svg`}
                          alt={content.buttonLabelForRightBar}
                        />
                      </span>
                      <span className="name">
                        {content.buttonLabelForRightBar}
                      </span>
                    </a>
                  </>
                );
              })}
            </div>
          </div>
          <div className="block-header mt-25">
            <p className="block-description">
              You may be very young, in your 20s or 30s, feel completely normal
              and still have a risk of heart disease based on your levels of
              ApoB particles. Knowing your risk is very important because the
              disease progresses gradually overtime and with some precaution and
              care, you can drastically reduce heart disease risk for a longer,
              healthy life.
              <SourceBtnWithDetail detailText="Richardson, T. G. et al. Effects of apolipoprotein B on lifespan and risks of major diseases including type 2 diabetes: a mendelian randomisation analysis using outcomes in first-degree relatives. Lancet Healthy Longev 2, e317–e326 (2021) Sniderman, A. D. et al. Age and Cardiovascular Risk Attributable to Apolipoprotein B, Low-Density Lipoprotein Cholesterol or Non-High-Density Lipoprotein Cholesterol. J. Am. Heart Assoc. 5, (2016)" />
            </p>
            <br />
            <p className="block-description">
              The American Heart Association recommends that all adults, age 20
              and older have their cholesterol and other traditional risk
              factors regularly checked.
              <SourceBtnWithDetail detailText="Grundy, S. M. et al. 2018 AHA/ACC/AACVPR/AAPA/ABC/ACPM/ADA/AGS/APhA/ASPC/NLA/PCNA Guideline on the Management of Blood Cholesterol: A Report of the American College of Cardiology/American Heart Association Task Force on Clinical Practice Guidelines. Circulation 139, e1082–e1143 (2019)" />
            </p>
          </div>
        </section>
        <DiseaseSection diseaseCardDetails={diseaseCardDetails} />
        <ResultSummarySection
          userResult={userResult}
          dynamicText={dynamicText}
        />
        <LifeStyleChangeAdviceSection />
        <MedInfoSection />
        {/* <EndlessHealthPlugSection healthPlanCardDetails={healthPlanCardDetails} /> */}
        <DisclaimerSection />
      </div>
    </div>
  );
}

export default Dashboard;
