import React, { useEffect } from 'react';
import { Result, Space } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { pageTitles } from '../../constants/appDefaults';
import { sendEmailVerification } from 'firebase/auth';
import { auth } from '../../config/firebase';
import { handleUnauthorize, notify } from '../../utils/common';

const Verify = () => {
    const navigate = useNavigate();
    useEffect(() => {
        document.title = pageTitles.verify;
    }, []);

    const resendVerificationEmail = () => {
        sendEmailVerification(auth.currentUser)
            .then((res) => {
                notify.success('Verification link resent successfully! Please check your email.');
                handleUnauthorize();
                navigate("/")
            })
            .catch((error) => {
                if (error.code === "auth/too-many-requests") {
                    notify.error("Too many attempts. Please try again after sometime.");
                } 
                console.error(error);
            });
    }

    return (
        <React.Fragment>
            <div className="login-container verify-container">
                <div className="white-box verify-box">
                    <div className="white-box-content">
                        <Result
                            className="verify-result-box"
                            icon={<img src="/images/verify_email_icon.png" alt="Verify Email" height={152} />}
                            title="Verify your email"
                            subTitle="Verify your account by clicking the link we've sent to your email."
                            extra={<Space size="large" direction="vertical" className="w-100">
                                {/* <Button type="primary" size="large" className="login-btn">Open mail</Button> */}
                                <Link to="/" className="bottom-link" onClick={resendVerificationEmail} >Resend verification link</Link></Space>}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default Verify;