import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { auth } from "../config/firebase";


export const notify = {
  success: (msg) => {
    toast.clearWaitingQueue();
    toast.dismiss();

    toast.success(msg, {
      position: "top-right",
      closeOnClick: true,
      pauseOnHover: true,
    });
  },
  error: (msg) => {
    toast.clearWaitingQueue();
    toast.dismiss();

    toast.error(msg, {
      position: "top-right",
      closeOnClick: true,
      pauseOnHover: true,
    });
  }
};

export const handleUnauthorize = () => {
  auth.signOut(auth).then(() => {
    localStorage.clear()
    sessionStorage.clear();
    window.location.href = "/"
    Cookies.set('authToken', "")
  }).catch((error) => {
    // An error happened.
  });
}

export const formatFirebaseMessages = (joinedMsg) => {
  if(joinedMsg){
    let convertedString = joinedMsg.toLowerCase().replace(/_/g, ' ');
    convertedString = convertedString.charAt(0).toUpperCase() + convertedString.slice(1);
    return convertedString;
  }else{
    return "";
  }
}
