
import React, { useEffect } from 'react';
import { Button, Form, Input, Typography, Checkbox, Select, InputNumber, Radio } from 'antd';
import { useNavigate } from 'react-router-dom';
import { required } from '../../utils/antdFormValidtion';
import { pageTitles } from '../../constants/appDefaults';
import { API_ENDPOINTS } from '../../constants/apiUrl';
import axiosHelper from '../../utils/axiosHelper';

const { Title, Paragraph } = Typography;
const { Option } = Select;

const Register = () => {
    const navigate = useNavigate();
    useEffect(() => {
        document.title = pageTitles.register;
    }, []);

    const years = [];
    for (let i = new Date().getFullYear(); i >= 1900; i--) {
        years.push(i);
    }

    const validateEmail = (rule, value) => {
        if (!value || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            return Promise.reject('Please enter a valid email address!');
        }
        return Promise.resolve();
    };


    const validatePhoneNumber = (rule, value) => {
        if (!value || !/^\d{10}$/.test(value)) {
            return Promise.reject('Please enter a 10-digit phone number!');
        }
        return Promise.resolve();
    };

    const onSubmit = async (values) => {
        let reqData = {
            "first_name": values.first_name,
            "last_name": values.last_name,
            "gender": values.gender[0],
            "phone_number": values.phone_number,
            "email": values.email,
            "birth_year": values.birth_year,
            "birth_month": values.birth_month,
            "birth_day": values.birth_day,
            "accepted_responsibility": values.accepted_responsibility,
            "acknowledged_terms": values.acknowledged_terms,
            "kit_last_digits": values.kit_last_digits,
            "is_fasting": values.is_fasting,
            "signed_document": true,
            "address": "ASDASDASD"
        };

        console.log(reqData);
        try {
            const response = await axiosHelper.post(API_ENDPOINTS.registerEvent, reqData);
            console.log(response);
            navigate('/events/' + response.id );
        } catch (error) {
            console.log("FAIL");
        }
    };
    const handleResponsibilityCheck = () => {
        console.log('we probably want to do something with this data? or maybe just validate it is checked?')
    }


    return (
        <React.Fragment>
            <div className="login-container">
                <Title className="section-title">Heart health test</Title>
                <div className="white-box">
                    <div className="white-box-content">
                        <div className="block-title-box">
                            <Title level={3} className="block-title m-0">Register your test!</Title>
                            <Paragraph className="block-subtitle m-0">Entering correct information ensures our lab and system can efficiently track your kit and provide timely updates</Paragraph>
                        </div>
                        <Form
                            name="basic"
                            onFinish={onSubmit}
                            autoComplete="off"
                            layout="vertical"
                        >
                            <p>
                                What is your first name? <span style={{ color: 'red' }}>*</span>
                            </p>
                            <Form.Item
                                name="first_name"
                                rules={[required()]}
                            >
                                <Input placeholder="First Name"/>
                            </Form.Item>
                            <p>
                                What is your last name? <span style={{ color: 'red' }}>*</span>
                            </p>
                            <Form.Item
                                name="last_name"
                                rules={[required()]}
                            >
                                
                                <Input placeholder="Last Name" />
                            </Form.Item>
                            <p>
                                What is your biological sex assigned at birth? <span style={{ color: 'red' }}>*</span>  
                            </p>
                            <Form.Item
                                name="gender"
                                rules={[{ required: true, message: 'Please select your gender!' }]}
                            >
                                <Radio.Group>
                                    <Radio value="M">Male</Radio>
                                    <Radio value="F">Female</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <p>
                                 Enter your 10-digit phone number (for eg. <strong>1234567890</strong>): <span style={{ color: 'red' }}>*</span>
                            </p>
                            <Form.Item
                                name="phone_number"
                                rules={[
                                    { required: true, message: 'Please enter your phone number!' },
                                    { validator: validatePhoneNumber }
                                ]}
                            >
                                <Input placeholder="10-digit phone number" />
                            </Form.Item>
                            <p>
                                 What is your personal email that you want notification that your results are ready sent to? <span style={{ color: 'red' }}>*</span>
                            <br/>
                            <strong>
                                Double check the email because this is how we will send you results!!
                            </strong> 
                            </p>


                            <Form.Item
                                name="email"
                                rules={[
                                    { required: true, message: 'Please enter your email address!' },
                                    { validator: validateEmail }
                                ]}
                            >
                                <Input placeholder="Enter your email address" />
                            </Form.Item>

                            <Form.Item
                                name="birth_year"
                                label="What year were you born?"
                                rules={[{ required: true, message: 'Please select your birth year!' }]}
                            >
                                <Select placeholder="Select year">
                                    {years.map((year) => (
                                        <Option key={year} value={year}>{year}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="birth_month"
                                label="What month were you born?"
                                rules={[{ required: true, message: 'Please select your birth month!' }]}
                            >
                                <Select placeholder="Select month" >
                                    <Option value="1">January</Option>
                                    <Option value="2">February</Option>
                                    <Option value="3">March</Option>
                                    <Option value="4">April</Option>
                                    <Option value="5">May</Option>
                                    <Option value="6">June</Option>
                                    <Option value="7">July</Option>
                                    <Option value="8">August</Option>
                                    <Option value="9">September</Option>
                                    <Option value="10">October</Option>
                                    <Option value="11">November</Option>
                                    <Option value="12">December</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="birth_day"
                                label="What day were you born?"
                                rules={[{ required: true, message: 'Please enter your birth day!' }]}
                            >
                                <InputNumber placeholder="Day" min={1} max={31} style={{ width: '100%' }} />
                            </Form.Item>

                            <div styles={{ margin : '40px' }}>
                                <Paragraph>
                                    Endless Health's test involves a thoroughly validated, high accuracy, prick testing, also known as capillary blood testing, is a method used to obtain a small blood sample for various medical tests. Tests are processed in an FDA-certified CLIA lab. Although it is a relatively safe procedure, there are some contraindications and situations where caution is required:
                                </Paragraph>
                                <Paragraph>
                                     <strong>Hemophobia</strong>: Individuals with hemophobia have an irrational fear of blood. Performing a finger prick test on such individuals might cause anxiety, fainting, or other distressing symptoms. In such cases, alternative methods of testing or desensitization techniques might be considered.
                                </Paragraph>
                                <Paragraph>
                                     <strong>Hemophilia</strong>: Hemophilia is a medical condition where the blood does not clot normally due to a lack of sufficient blood-clotting proteins (clotting factors). People with hemophilia are at a higher risk of prolonged bleeding even from minor injuries. For individuals with hemophilia or other clotting disorders, healthcare professionals need to take special precautions, such as applying additional pressure after the prick to ensure clotting, and monitor for any signs of prolonged bleeding.
                                </Paragraph>
                                <Paragraph>
                                     <strong>Skin Condition</strong>: People with certain skin conditions (e.g., eczema, psoriasis) may experience more discomfort or difficulty in obtaining an accurate sample. Skin infections or open wounds are contraindications as they can introduce contaminants into the blood sample.
                                </Paragraph>
                                <Paragraph>
                                    <strong>Poor Circulation</strong>: Conditions affecting blood circulation, such as Raynaud’s disease, can make obtaining a capillary blood sample difficult.
                                </Paragraph>
                                <Paragraph>
                                    <strong>Low Platelet Count</strong>: Individuals with low platelet count or clotting disorders might be at higher risk of prolonged bleeding after the test.
                                </Paragraph>
                                <Paragraph>
                                   <strong>Dehydration</strong>: Severe dehydration can affect the volume of the blood sample obtained and possibly the accuracy of the test results.
                                </Paragraph>
                                <Paragraph>
                                    <strong>Edema</strong>: Swelling in the fingers due to fluid accumulation (edema) can make obtaining a blood sample difficult.
                                </Paragraph>
                                <Paragraph>
                                    <strong>Hypotension or Shock</strong>: In cases of severely lowered blood pressure or shock, obtaining a capillary blood sample might be difficult.
                                </Paragraph>
                                <Paragraph>
                                    <strong>Hyperkeratosis</strong>: Thickened skin due to chronic inflammation or irritation can make obtaining a blood sample more challenging.
                                </Paragraph>
                                <Paragraph>
                                    <strong>Cold Temperature</strong>: Cold fingers or exposure to cold environments can constrict blood vessels and make blood sampling difficult.
                                </Paragraph>
                                <Paragraph>
                                    <strong>Burns or Scars</strong>: Finger prick testing should not be performed on fingers that have burns, scars, or other injuries as it can cause additional pain and affect the accuracy of the sample.
                                </Paragraph>
                                <Paragraph>
                                    <strong>Allergy to Cleaning Agents</strong>: If a person has an allergy to the cleaning agent (usually alcohol swab) used before the prick, an alternative method of sanitization or a different site should be considered.
                                </Paragraph>
                            </div>

                            <p>
                                Acknowledge that none of these contraindications are a concern for you, otherwise speak with the organizers of the testing event:
                            </p>

                            <Form.Item
                                name="accepted_responsibility"
                                valuePropName="checked"
                                rules={[{ required: true, message: 'Please acknowledge the contraindications!' }]}
                            >
                                <Checkbox onChange={handleResponsibilityCheck}>None of these concerns apply for me and I accept responsibility <span style={{ color: 'red' }}>*</span></Checkbox>
                            </Form.Item>

                            <div styles={{ margin : '40px' }}>
                                <Paragraph>
                                    By participating in this lipid panel screen, you agree to abide by Endless Health Inc's terms and conditions: <br></br>
                                <a href='https://www.besthearttest.com/terms-and-conditions'>https://www.besthearttest.com/terms-and-conditions</a> <br></br>
                                    And agree to and acknowledge Endless Health's privacy policy: <br></br>
                                <a href='https://www.besthearttest.com/about-4'>https://www.besthearttest.com/about-4</a> <br></br>
                                    And agree to Endless Health's HIPAA notice of privacy practices: <br></br>
                                <a href='https://www.besthearttest.com/hipaa-privacy-policy'>https://www.besthearttest.com/hipaa-privacy-policy</a>
                                </Paragraph>

                                <Paragraph>
                                    The most important parts of the terms and policy are that you acknowledge that you are engaging in a medical test that will generate a medical result, which will be ordered and reviewed by a physician, and returned to you in a secure web portal in accordance with HIPAA. 
                                    <br></br><strong>
                                        Endless Health does not provide medical advice or medical care. It offers high quality laboratory testing and research-backed lifestyle suggestions for a heart healthy lifestyle. You should discuss your test results with your primary physician or care team. 
                                    </strong>
                                </Paragraph>
                            </div>
                            
                            <Form.Item
                                name="acknowledged_terms"
                                valuePropName="checked"
                                // rules={[{ required: true, message: 'Please acknowledge the contraindications!' }]}
                            >
                                <Checkbox onChange={handleResponsibilityCheck}>Yes<span style={{ color: 'red' }}>*</span></Checkbox>
                            </Form.Item>

                            <p>
                                Enter the <strong>last 4 digits of the bar code on the back of your test card:</strong><span style={{ color: 'red' }}>*</span>
                            </p>
                            <Form.Item
                                name="kit_last_digits"
                                rules={[
                                    // { required: true, message: 'Please enter a four-digit number!' },
                                    { len: 4, message: 'Number must be exactly four digits!' },
                                    { pattern: /^\d+$/, message: 'Number must contain only digits!' },
                                ]}
                            >
                                <Input placeholder="Enter a four-digit number"/>
                            </Form.Item>

                            <p>
                                Are you currently fasting? <span style={{ color: 'red' }}>*</span>
                            </p>

                            <Form.Item
                                name="is_fasting"
                                rules={[{ required: true, message: 'Please select if you are fasting!' }]}
                            >
                                <Radio.Group>
                                    <Radio value="true">Yes</Radio>
                                    <Radio value="false">No</Radio>
                                </Radio.Group>   
                            </Form.Item>

                            {/* <p>
                                Type your name again to sign this document and confirm that the above information is true:<span style={{ color: 'red' }}>*</span>
                            </p> */}

                            <div className="button-section align-center">
                                <Button type="primary" htmlType="submit" size="large" className="login-btn">Sign up</Button>
                            </div>
                        </Form>

                    </div>
                </div>
            </div>
            <div className="bottom-info">
                <p className="in-case-of-any-issue text-large-20">
                    <span>In case of any issue please contact us at </span>
                    <a href="mailto: help@endless.health" className="strong">help@endless.health</a>
                </p>
            </div>
        </React.Fragment>
    )

};

export default Register;