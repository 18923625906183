
const BASE_URL = process.env.REACT_APP_API_HOST || ''

export const API_ENDPOINTS = {
    backendBaseUrl: BASE_URL,
    checkUserByEmail: `${BASE_URL}/v1/verify-email-address/`,
    registerUser: `${BASE_URL}/v1/add-new-user/`,
    getUserDetailsByEmail: `${BASE_URL}/v1/get-user-info/`,
    getUserResults: `${BASE_URL}/v1/get-eh-user-results/`,
    registerEvent: `${BASE_URL}/api/v2/events/`
  }
