import { Col, Row } from 'antd'
import React from 'react'
import DiseaseCard from './DiseaseCard'

function DiseaseSection(props) {
    return (
        <section className="heart-health-main-section">
            <div className="block-header">
                <h3 className="block-title">Plaque builds up over time based on the amount of ApoB particles in the blood</h3>
                <p className="block-description">Plaque buildup may start during teenage. Gradually over the lifetime, if there is not enough caution and care, the buildup increases and becomes a risk to heart health. There are 3 forms of risk:</p>
            </div>
            <div>
                <Row gutter={[47, 20]}>
                    {
                        props.diseaseCardDetails.map((detail, index) => {
                            return (
                                <Col md={24} lg={8} key={index}>
                                    <DiseaseCard diseaseCardDetails={detail} />
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </section>
    )
}

export default DiseaseSection