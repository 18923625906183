import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Result, Space, Typography} from 'antd';
import { pageTitles } from '../../constants/appDefaults';
import { useParams } from 'react-router';
import { API_ENDPOINTS } from '../../constants/apiUrl';

const RegisterDone = () => {

    const { Title} = Typography;
    const { id } = useParams();    
    const [showInput, setShowInput] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [isDone, setIsDone] = useState(false); // Add state for done message
    const toggleInput = () => setShowInput(!showInput)
    const onSubmit = async (values) => {
        try {
            setButtonLoading(true); // Set button loading
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Content-Type", "application/json");
            const raw = JSON.stringify({
                "kit_last_digits": values.kit_last_digits
            });
            const requestOptions = {
                method: "PUT",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };
            const response = await fetch(`${API_ENDPOINTS.registerEvent}${id}/`, requestOptions);
            const result = await response.text();
            console.log(result);
            console.log('Request sent!', response);
            setIsDone(true); 
            setTimeout(() => {
                setButtonLoading(false); // Reset button loading
            }, 2000);
        } catch (error) {
            console.error('Failed to send request', error);
            // Handle error
        } finally {
            setButtonLoading(false);
        }
    };    
    useEffect(() => {
        document.title = pageTitles.register;
    }, []);

    return (
        <React.Fragment>
            <div className="login-container verify-container">
            <Title className="section-title">Heart Health Report</Title>
                <div className="white-box verify-box">
                    <>
                        <div className="white-box-content">
                        <Result
                            className="verify-result-box"
                            icon={<img src="/images/verification-heart_icon.png" alt="Verification Heart" height={152} />}
                            title="Registration Done!"
                            subTitle="Please don't close this page"
                            extra={
                            <Space size="large" direction="vertical" className="w-100">
                                {!isDone && (
                                <>
                                    {!showInput && (
                                        <Button 
                                            type="primary" 
                                            size="large" 
                                            className="login-btn" 
                                            onClick={toggleInput}
                                        >
                                            I need a new kit
                                        </Button>
                                    )}
                                    {showInput && (
                                        <Form 
                                        onFinish={onSubmit}
                                        name="basic"
                                        >
                                            <p>Please input last four digit number of your new kit number<span style={{ color: 'red' }}>*</span></p>
                                            <Form.Item
                                                name="kit_last_digits"
                                                rules={[
                                                    { required: true, message: 'Please enter a four-digit number!' },
                                                    { len: 4, message: 'Number must be exactly four digits!' },
                                                    { pattern: /^\d+$/, message: 'Number must contain only digits!' },
                                                ]}
                                            >
                                                <Input placeholder="Enter a four-digit number"/>
                                            </Form.Item>
                                            <Button type="primary" htmlType="submit" size="large" className='login-btn' loading={buttonLoading}>Update New Kit</Button>
                                        </Form>
                                    )}    
                                </>
                                )}
                                {isDone && (
                                    <p>You are all set! <br></br>Please take your second test</p>
                                )}
                            </Space>}
                        />
                        </div>

                    </>
                </div>
            </div>
        </React.Fragment>
    )
};
export default RegisterDone;