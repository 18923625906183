import React from 'react'
import { Table } from 'antd'
import SourceBtnWithDetail from '../../common/SourceBtnWithDetail';

const columns = [
    {
        title: 'Medicines',
        dataIndex: 'medicines',
        width: '66%',
    },
    {
        title: 'Estimated LDL-C drop',
        dataIndex: 'estimatedLdc',
    },
];
const data = [
    {
        key: '1',
        medicines: 'Statins',
        estimatedLdc: '18% to 55%',
    },
    {
        key: '2',
        medicines: 'Bile acid sequestrants ',
        estimatedLdc: '10% to 27%',
    },
    {
        key: '3',
        medicines: 'Cholesterol absorption inhibitor',
        estimatedLdc: '18% to 25%',
    },
    {
        key: '4',
        medicines: 'PCSK9 monoclonal antibodies',
        estimatedLdc: '45% - 64%',
    },
    {
        key: '5',
        medicines: 'ATP Citrate lyase inhibitor',
        estimatedLdc: '17% - 18%',
    },
    {
        key: '6',
        medicines: 'Small interfering RNA ',
        estimatedLdc: '48% - 52%',
    },
];

function MedInfoSection() {
    return (
        <section className="heart-health-main-section">
            <div className="block-header">
                <h3 className="block-title">Supplements and medications can also help reduce heart disease risk. Talk with your doctor for advice.</h3>
                <p className="block-description">
                    There are many supplements and medicines that can also help to bring down the level of ApoB and LDL-C. You must consult your case with your doctor for prescribing the right ones.*
                    <SourceBtnWithDetail detailText="Lloyd-Jones DM, et al. J Am Coll Cardiol. 2022 Oct, 80 (14) 1366–1418." />
                </p>

                <Table
                    className='medinfo-table'
                    columns={columns}
                    dataSource={data}
                    bordered
                    pagination={false}
                    borderColor="#626262"
                />
                <p className="block-description">* Consult your doctor to know if you need to take any supplements</p>
            </div>
        </section>
    )
}

export default MedInfoSection