import axios from 'axios';
import { getAuth } from 'firebase/auth';

export const getHeaders = async ()=> {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  if (!currentUser) {
    throw new Error("User not logged in")
  }
  const token = await currentUser.getIdToken()
  return {
    'Authorization' : `Bearer ${token}`
  };
}

class AxiosHelper {
  constructor() {
    this.instance = axios.create({
      timeout: 50000, // In milliseconds
    });    
    delete this.instance.defaults.headers.common['crossDomain'];
  }

  async get(endpoint, params) {
    const headers = await getHeaders()
    try {
      const response = await this.instance.get(endpoint, {
        params,
        headers,
      });
      return response.data;
    } catch (error) {
    //   console.error(error);
      throw error;
    }
  }

  async post(endpoint, data) {
    try {
      let headers = await getHeaders()

      if (data instanceof FormData) {
        headers = {
          ...headers,
          'Content-Type': 'multipart/form-data',
        };
      }

      const response = await this.instance.post(endpoint, data, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
    //   console.error(error);
      throw error;
    }
  }

  async put(endpoint, data) {
    try {

      let headers = await getHeaders()

      if (data instanceof FormData) {
        headers = {
          ...headers,
          'Content-Type': 'multipart/form-data',
        };
      }
      const response = await this.instance.put(endpoint, data, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
    //   console.error(error);
      throw error;
    }
  }

  async delete(endpoint) {
    try {
      const response = await this.instance.delete(endpoint, {
        headers: await getHeaders(),
      });
      return response.data;
    } catch (error) {
    //   console.error(error);
      throw error;
    }
  }
}

const axiosHelper = new AxiosHelper();
export default axiosHelper;
