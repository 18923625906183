
import React from 'react';

let adviceDetails = [
  {
    title: 'Diet improvements can reduce LDL-C levels by 20-30%',
    description: 'Diet improvements can reduce LDL-C levels by <strong> 20-30%</strong> in only a few weeks by following simple guidelines',
    image: '/images/balanced-diet.png',
    cardHeading: 'BALANCED DIET',
    color: '#5BCA99'
  },
  {
    title: 'Regular Exercise',
    description: 'Walking just <strong>4,000</strong> steps or 30 minutes briskly a day can cut ApoB and LDL-C levels by <strong>5-10%</strong>',
    image: '/images/regular-exercise.png',
    cardHeading: 'REGULAR EXERCISE',
    color: '#EF3054'
  },
  {
    title: 'Stress Management',
    description: 'From meditation to therapy to yoga, taking steps to <strong>take control of your stress</strong> can reduce LDL-C by <strong>5-10%<strong/>',
    image: '/images/stress-management.png',
    cardHeading: 'QUALITY SLEEP',
    color: '#965BE1'
  },
  {
    title: 'Quality Sleep',
    description: '<strong>Better sleep </strong> drops ApoB. We have  <strong>science-backed tips </strong> to optimize length and quality of your sleep!',
    image: '/images/girl-sleeping.png',
    cardHeading: 'Mood & relaxation',
    color: '#FBA600'
  },
  {
    title: 'Smoking',
    description: '<strong>Kick the smoking habit </strong> to knock the toxicity of  ApoB and LDL-C',
    image: '/images/quit-smoking.png',
    cardHeading: 'QUIT SMOKING',
    color: '#6B7FD7'
  },
];

function LifeStyleChangeAdviceSection() {
  return (
    <section className="heart-health-main-section">
      <div className="block-header">
        <h3 className="block-title">Good news is that the risks of heart diseases can be reduced and even reversed by certain lifestyle changes.</h3>
        <p className="block-description" style={{ marginBottom: "32px" }}>The most important care you can take to bring down the risk of heart disease is making changes to your lifestyle. Research shows several interventions like diet improvement, regular exercise, and regular sleep can help reduce and even reverse your risk of heart disease. Click to see what it means:</p>

        {adviceDetails.map(item => (
          <>
            <h4 className='common-label' style={{ color: `${item.color}` }}>{item.cardHeading}</h4>
            <div className='advice-item' key={item.title}>
                <img src={item.image} alt={item.title} />
              <div>
                <h4 className='advice-title' dangerouslySetInnerHTML={{ __html: item.description }} ></h4>
                <a className='learn-more-btn' href='https://www.endless.health/endless-health-blogs' target="_blank" rel="noreferrer" >
                  Learn How
                </a>
              </div>
            </div>
          </>
        ))}
      </div>
    </section>
  );
}

export default LifeStyleChangeAdviceSection;
