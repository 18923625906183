import React from "react";

const CustomProgressBar = (props) => {
    const { bioMarkerValue } = props;
    const progressBarData = [
        {
            tagColor: "#B92DEB",
            tagLabel: "Severe loss of kidney function",
            currentRangeValue: 10,
            nextRangeValue: 30
        },
        {
            tagColor: "#FF4237",
            tagLabel: "Moderate loss of kidney function",
            currentRangeValue: 30,
            nextRangeValue: 60
        },
        {
            tagColor: "#FFCC00",
            tagLabel: "Mild loss of kidney function",
            currentRangeValue: 60,
            nextRangeValue: 90
        },
        {
            tagColor: "#97DFA2",
            tagLabel: "Optimal kidney function",
            currentRangeValue: 90,
            nextRangeValue: 180
        },
    ]

    const initialRangeValue = 10;
    const lastRangeValue = 180;
    const differenceBetweenInitialAndLastValue = lastRangeValue - initialRangeValue;
    return (
        <div className="custom-progress-bar">
            <div className="bar-line">
                {
                    progressBarData.map((el, index) => {
                        let diffInBioMarkerValueAndCurrentRangeValue = (bioMarkerValue - el.currentRangeValue) > 0 ? bioMarkerValue - el.currentRangeValue : 0.1;
                        let totalRangeOfCurrentPart = el.nextRangeValue - el.currentRangeValue;
                        let indicatorPosition = el.currentRangeValue <= bioMarkerValue && el.nextRangeValue > bioMarkerValue ?
                            ((diffInBioMarkerValueAndCurrentRangeValue) / (totalRangeOfCurrentPart)) * 100 : 0;
                        return (
                            <>
                                {/* {index == 0 ? <div className="pre-first-level level" style={{
                                    backgroundColor: el.tagColor,
                                    width: "2.5%"
                                }}>
                                    {
                                        bioMarkerValue < initialRangeValue && (
                                            <span className="indicator" style={{ left: "50%" }}></span>
                                        )}
                                </div> : ""} */}
                                <div className="level" style={{
                                    backgroundColor: el.tagColor,
                                    width: ((totalRangeOfCurrentPart) / differenceBetweenInitialAndLastValue) * 100 + "%"
                                }}>
                                    {indicatorPosition > 0 && <span className="indicator" style={{ left: `${indicatorPosition}%` }}></span>}
                                </div>
                                {/* {index == progressBarData.length - 1 ? <div className="post-last-level level" style={{
                                    backgroundColor: el.tagColor,
                                    width: "2.5%"
                                }}>
                                    {bioMarkerValue > lastRangeValue && (
                                        <span className="indicator" style={{ left: "50%" }}></span>
                                    )}
                                </div> : ""} */}
                            </>
                        )
                    })
                }
            </div>
            <div className="bar-line-text">
                {
                    progressBarData.map((el, index) => {
                        let totalRangeOfCurrentPart = el.nextRangeValue - el.currentRangeValue;
                        return (
                            <>
                                {/* {index == 0 ? <div style={{ width: "2.5%" }}></div> : ""} */}
                                <div style={{
                                    width: ((totalRangeOfCurrentPart) / differenceBetweenInitialAndLastValue) * 100 + "%"
                                }}>{el.tagLabel}</div>
                                {/* {index == progressBarData.length - 1 ? <div style={{ width: "2.5%" }}></div> : ""} */}
                            </>
                        )
                    })
                }
            </div>
            <div className="bar-line-sparator">
                {
                    progressBarData.map((el, index) => {
                        let totalRangeOfCurrentPart = el.nextRangeValue - el.currentRangeValue;
                        return (
                            <>
                                {index === 0 ? <div style={{ marginLeft: "-2.3%" }}><span>{el.currentRangeValue}</span></div> : ""}
                                <div style={{
                                    width: ((totalRangeOfCurrentPart) / differenceBetweenInitialAndLastValue) * 100 + "%"
                                }}>
                                    <span>{el.nextRangeValue}</span>
                                </div>
                                {/* {index == progressBarData.length - 1 ? <div style={{ width: "2.5%" }}></div> : ""} */}
                            </>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default CustomProgressBar;