import { Col, Row } from 'antd'
import React from 'react'

function DiseaseCard({ diseaseCardDetails }) {
    return (
        <div className="disease-card">
            <p className="title">{diseaseCardDetails.diseaseCardTitle}</p>
            <img src={diseaseCardDetails.diseaseCardImage} alt={diseaseCardDetails.diseaseCardTitle} />
            <p className="disease-description">
                {diseaseCardDetails.diseaseDescription}
            </p>
            {diseaseCardDetails.diseaseImageGrid.map(item => (
                <React.Fragment key={item.id}>
                    <Row className="img-content-wrapper" gutter={16}>
                        <Col span={8} className='img-wrapper'>
                            <img src={item.diseaseImage} alt={item.imageContentTitle} />
                        </Col>
                        <Col span={16}>
                            <p><strong>{item.imageContentTitle}</strong></p>
                            <p>{item.imageContent}</p>
                        </Col>
                    </Row>
                </React.Fragment>
            ))}

        </div>
    )
}

export default DiseaseCard