import React, { useEffect } from 'react';
import { Button, Result, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { sendEmailVerification } from 'firebase/auth';
import { auth } from '../../config/firebase';
import { useSelector } from 'react-redux';
import { handleUnauthorize, notify } from '../../utils/common';
import { pageTitles } from '../../constants/appDefaults';

const VerificationPending = () => {
    const navigate = useNavigate();
    const currentAuthUser = useSelector(store => store.auth);

    useEffect(() => {
        document.title = pageTitles.verificationPending;
    }, []);

    useEffect(() => {
        if (currentAuthUser?.user?.emailVerified) {
            navigate("/dashboard");
        }
    }, [currentAuthUser?.user?.emailVerified, navigate])

    const resendVerificationEmail = () => {
        sendEmailVerification(auth.currentUser)
            .then(() => {
                notify.success('Verification link resent successfully! Please check your email.');
                handleUnauthorize();
                navigate("/")
            })
            .catch((error) => {
                if (error.code === "auth/too-many-requests") {
                    notify.error("Too many attempts. Please try again after sometime.");
                }
                console.error(error);
            });
    }
    return (
        <React.Fragment>
            <div className="login-container verify-container">
                <div className="white-box verify-box">
                    <div className="white-box-content">
                        <Result
                            className="verify-result-box"
                            icon={<img src="/images/verification_icon.png" alt="Verification" height={152} />}
                            title="Account not yet verified"
                            subTitle="We found your account, but it has not been verified yet. Please verify it by clicking the link we’ve sent to your email."
                            extra={<Space size="large" direction="vertical" className="w-100"><Button type="primary" size="large" className="login-btn">Open mail</Button><span className="bottom-link" onClick={resendVerificationEmail}>Resend verification link</span></Space>}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default VerificationPending;