

/** Field required validation */
export const required = (label) => {
    return { required: true, message: label ? label : 'Required' }
};

/** Field email validation */
export const checkEmail = (label, min = 6, max = 50) => {
    return {
        type: 'email',
        message: label ? label : 'Invalid email.',
        min: min, // minimum length of email
        max: max, // maximum length of email}
    }
}

/** Field min length validation */
export const minLength = (length) => {
    return { min: length, message: `Minimum length is ${length} characters.` }
};

/** Field max length validation */
export const maxLength = (length) => {
    return { max: length, message: `Maximum length is ${length} characters.` }
};

/** Field white validation */
export const whiteSpace = () => {
    return { whitespace: true, message: `Whitespace is not allowed.` }
};
