import React from "react";

const SmallProgressBar = (props) => {
    const { bioMarkerValue, initialRangeValue, lastRangeValue, progressBarData, markerSlug } = props;
    const differenceBetweenInitialAndLastValue = lastRangeValue - initialRangeValue;
    // console.log('SmallProgressBar progressBarData', progressBarData, bioMarkerValue, initialRangeValue, lastRangeValue);
    return (
        // <div className={`small-progress-bar ${status ? status : 'optimal'}`}><span className="bar-pointer">&nbsp;</span></div>
        <div className="custom-progress-bar small-progress-bar">
            <div className="bar-line">
                {
                    progressBarData?.length > 0 ? progressBarData.map((el, index) => {
                        let diffInBioMarkerValueAndCurrentRangeValue = (bioMarkerValue - el.currentRangeValue) > 0 ? bioMarkerValue - el.currentRangeValue : 0.1;
                        let totalRangeOfCurrentPart = el.nextRangeValue - el.currentRangeValue;
                        let indicatorPosition = el.currentRangeValue <= bioMarkerValue && el.nextRangeValue > bioMarkerValue ?
                            ((diffInBioMarkerValueAndCurrentRangeValue) / (totalRangeOfCurrentPart)) * 100 : 0;
                        return (
                            <>
                                {(index === 0 && markerSlug !== "egfr") ? <div className="pre-first-level level" style={{
                                    backgroundColor: bioMarkerValue < initialRangeValue ? el.tagColor : "#ccc",
                                    width: "2.5%"
                                }}>
                                    {bioMarkerValue < initialRangeValue && (
                                        <span className="indicator" style={{ left: "50%" }}></span>
                                    )}
                                </div> : ""}
                                <div className="level" style={{
                                    backgroundColor: indicatorPosition > 0 ? el.tagColor : "#ccc",
                                    width: ((totalRangeOfCurrentPart) / differenceBetweenInitialAndLastValue) * 100 + "%"
                                }}>
                                    {indicatorPosition > 0 && <span className="indicator" style={{ left: `${indicatorPosition}%` }}></span>}
                                </div>
                                {(index === progressBarData.length - 1 && markerSlug !== "egfr") ? <div className="post-last-level level" style={{
                                    backgroundColor: bioMarkerValue > lastRangeValue ? el.tagColor : "#ccc",
                                    width: "2.5%"
                                }}>
                                    {bioMarkerValue > lastRangeValue && (
                                        <span className="indicator" style={{ left: "50%" }}></span>
                                    )}
                                </div> : ""}
                            </>
                        )
                    })
                        : ""
                }
            </div>
        </div>
    )
}

export default SmallProgressBar;