import React from "react";
import { Layout } from "antd";

const { Footer } = Layout;

const AppFooter = () => {
    return (
        <Footer className="footer">
            <p className="footer__text">© 2023 Endless Health</p>
        </Footer>
    )
}

export default AppFooter;