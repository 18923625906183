import { Steps, ConfigProvider } from "antd";
import React from 'react';

function StepSection(props) {
    const { userInfo } = props;
    // console.log(userInfo.user_orders[0].result_status);
    if (!userInfo || !userInfo.user_orders || userInfo.user_orders.length === 0 || !userInfo.user_orders[0] || userInfo.user_orders[0].result_status === 'ORDER_COMPLETE') {
        // Don't render anything if userInfo is null or undefined, or there are no orders, or the order status is ORDER_COMPLETE
        return null;
    }
    // TODO: Write a unit test for the condition above
    // Test cases should cover scenarios where userInfo is null, userInfo.user_orders is null or empty,
    // userInfo.user_orders[0] is null, and userInfo.user_orders[0].result_status is 'ORDER_COMPLETE'.
    // Additionally, write tests for the scenarios where userInfo is valid, and there are orders, but the order status is not 'ORDER_COMPLETE'.

    userInfo.status = userInfo.user_orders[0].result_status;
    const statusToTitleIndex = {
        'ORDER_ACCEPT' : 'ORDER ACCEPT',
        'ORDER_DECLINE':  'ORDER DECLINE',
        'TEST_ACCEPT' : 'TEST ACCEPT',
        'TEST_DECLINE' : 'TEST DECLINE',
        'TEST_COMPLETE' : 'TEST COMPLETE',
        'CANCELED' : 'CANCELED',
        'ORDER_COMPLETE' : 'RESULTS AVAILABLE'
    };
    const steps = [
        {
            title: 'ORDER ACCEPT',
            description: "Your order is accepted",
        },
        {
            title: 'ORDER DECLINE',
            status: userInfo.status === 'ORDER_DECLINE' ? 'error' : 'hidden',
            description: "A doctor has declined your test",
        },
        {
            title: 'TEST ACCEPT',
            description: "Sample has been accepted by the lab",
        },
        {
            title: 'TEST DECLINE',
            status: userInfo.status === 'TEST_DECLINE' ? 'error' : 'hidden',
            description: "Lab declined your test",
        },
        {
            title: 'TEST COMPLETE',
            description: "Your sample has been tested",
        },
        {
            title: 'CANCELED',
            status: userInfo.status === 'CANCELED' ? 'error' : 'hidden',
            description: "Your results will not be available"
        },
        {
            title: 'RESULTS AVAILABLE',
            description: "Test result is ready"
        },
    ];
    // Filter out steps with 'hidden' status
    const filteredSteps = steps.filter(step => {
        if (step.status === 'hidden') {
            return false;
        }

        if (userInfo.status === 'ORDER_DECLINE') {
            return step.title !== 'ORDER ACCEPT';
        }

        if (userInfo.status === 'TEST_DECLINE') {
            return step.title !== 'TEST ACCEPT';
        }

        if (userInfo.status === 'CANCELED') {
            return step.title !== 'TEST COMPLETE';
        }

        return true;
    });
    const currentStep = filteredSteps.findIndex(step => step.title === statusToTitleIndex[userInfo.status]);

    // Set status for each step individually
    const items = filteredSteps.map((step, index) => {
        const isDeclined = ['ORDER_DECLINE', 'TEST_DECLINE', 'CANCELED'].includes(step.title);
        // Define custom styles based on your requirements
        const customStyles = isDeclined
            ? {
                color: 'grey !important', // Set your custom styles for isDeclined items
            }
            : {}; // Empty object for non-declined items
        return {
            title: (
                <div className="ant-steps-item-title" style={customStyles}>
                    {step.title}
                </div>
            ),
            status: step.status || (index <= currentStep ? 'finish' : 'wait'),
            description: (
                <div className="ant-steps-item-description" style={customStyles}>
                    {step.description}
                </div>
            ),
            style: customStyles, // Add custom styles to each step
        };
    });
    return (

      <ConfigProvider
        theme={{
          components: {
            Steps: {
              /* here is your component tokens */
                // Seed Token
                dotSize: 20,
                descriptionMaxWidth: 300,
            }
          }
        }}
      >
        <Steps
            progressDot
            current={currentStep !== undefined ? currentStep : 0}
            items={items}
        />
      </ConfigProvider>
    );
}

export default StepSection;
