import React from "react";

function EndlessHealthCardioGuidance(props) {
  return (
    <section className="heart-health-main-section">
      <div className="block-header">
        <h3 className="block-title">Need guidance in your heart health?</h3>
        <div className="need-guidance">
          <img src="/images/apob1.png" alt="Need guidance" />
          <div>
            Receive personalized support from a Care Navigator. This free
            service offers expert assistance for those dealing with high
            cholesterol, familial hypercholesterolemia (FH), elevated
            Lipoprotein(a), and other cardiovascular conditions.
            <a
              className="connect-btn"
              href="https://familyheart.org/care-navigation-form"
              target="_blank"
              rel="noreferrer"
            >
              Connect with a Care Navigator
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EndlessHealthCardioGuidance;
