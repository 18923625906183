import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { auth } from '../../config/firebase';
import {checkEmail, maxLength, minLength, required, whiteSpace} from '../../utils/antdFormValidtion';
import { API_ENDPOINTS } from '../../constants/apiUrl';
import axiosHelper from '../../utils/axiosHelper';
import { pageTitles } from '../../constants/appDefaults';
import { notify } from '../../utils/common';

const { Title } = Typography;

const SignUp = () => {
    const navigate = useNavigate();
    const [termsAndConditionsModal, setTermsAndConditionsModal] = useState(false);
    const [privacyPolicyModal, setPrivacyPolicyModal] = useState(false);
    const [email, setEmail] = useState('');


    useEffect(() => {
        document.title = pageTitles.signup;
    }, []);

    const addUserToEhApp = async (userData) => {
        let reqData = {
            "email": userData.email,
            "first_name": userData.firstName,
            "last_name": userData.lastName
        };
        try {
            await axiosHelper.post(API_ENDPOINTS.registerUser, reqData);
        } catch (error) {
            console.log('error: ', error);
        }
    }

    const addNewUserToFirebase = async (values) => {
        let { email, password } = values;
        try {
            await createUserWithEmailAndPassword(auth, email, password);
            sendEmailVerification(auth.currentUser)
                .then(async () => {
                    await addUserToEhApp(values);
                    await auth.signOut();
                    notify.success("A verification email is sent to your email account. Please check your inbox.")
                    navigate("/verify")
                })
                .catch((error) => {
                    console.error(error);
                });
        } catch (error) {
            console.error(error);
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value.toLowerCase()); // Convert email to lowercase
        // TODO: Add unit test for handleEmailChange function
    };

    return (
        <React.Fragment>
            <div className="login-container">
                <Title className="section-title">Heart Health Report</Title>
                <div className="white-box">
                    <div className="white-box-content">
                        <div className="block-title-box">
                            <Title level={3} className="block-title m-0">Create your account</Title>
                        </div>
                        <Form
                            name="basic"
                            onFinish={(values) => addNewUserToFirebase({ ...values })}
                            autoComplete="off"
                            layout="vertical"
                        >
                            <Form.Item className="mb-0" style={{ marginBottom: 0 }}>
                                <Form.Item
                                    name="firstName"
                                    rules={[required()]}
                                    className="input-col2-left"
                                >
                                    <Input placeholder="First name" size="large" />
                                </Form.Item>
                                <Form.Item
                                    name="lastName"
                                    rules={[required()]}
                                    className="input-col2-right"
                                >
                                    <Input placeholder="Last name" size="large" />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item
                              name="email"
                              rules={[required(), checkEmail()]}
                            >
                                <Input
                                  placeholder="email"
                                  size="large"
                                  value={email}
                                  onChange={handleEmailChange}
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    required(), minLength(8), maxLength(15), whiteSpace(),
                                    () => ({
                                        pattern: /[!@#$%^&*()\-_=+{};:,<.>]/,
                                        message: "Password must contain at least one special character."
                                    })
                                ]}
                            >
                                <Input.Password placeholder="Create password" size="large" />
                            </Form.Item>
                            <div className="button-section align-center">
                                <Button type="primary" htmlType="submit" size="large" className="login-btn">Sign up</Button>
                            </div>
                            <div className="login-info-text">
                                By signing up you agree to common's <Link to="https://www.endless.health/terms-and-conditions" target="_blank"  className="strong">terms & conditions</Link> and <Link to="https://endless.health/privacy-policy" target="_blank" className="strong">privacy policy</Link>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <div className="bottom-info">
                <p className="in-case-of-any-issue text-large-20">
                    <span>In case of any issue please contact us at </span>
                    <a href="mailto: help@endless.health" className="strong">help@endless.health</a>
                </p>
            </div>
            {/* Terms and Conditions Modal */}
            <Modal
                title="Terms and Conditions"
                centered
                open={termsAndConditionsModal}
                onOk={() => setTermsAndConditionsModal(false)}
                onCancel={() => setTermsAndConditionsModal(false)}
                footer={null}
                width={1000}
            >
                <p>some contents...</p>
                <p>some contents...</p>
                <p>some contents...</p>
            </Modal>
            {/* Privacy Policy Modal */}
            <Modal
                title="Privacy Policy"
                centered
                open={privacyPolicyModal}
                onOk={() => setPrivacyPolicyModal(false)}
                onCancel={() => setPrivacyPolicyModal(false)}
                footer={null}
                width={1000}
            >
                <p>some contents...</p>
                <p>some contents...</p>
                <p>some contents...</p>
            </Modal>
        </React.Fragment>
    );
};
export default SignUp;