import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoutes = () => {
    const currentAuthUser = useSelector(store => store.auth);

    return (
        currentAuthUser?.isAuthenticated  ? <Outlet /> : <Navigate to='/' />
    )
}
export default PrivateRoutes;