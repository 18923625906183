import React from 'react'

function DisclaimerSection() {
    return (
        <section className="heart-health-main-section disclaimer-section">
            <div className="block-header">
                <h3 className="block-title">Disclaimer</h3>
                <p className="block-description">Educational content in this report is for informational purposes only and should not be interpreted as medical advice. Always consult with a healthcare professional for medical concerns; our products and blog posts are not replacements and are meant to be complementary to personalized medical care. Individual reactions to our products and recommendations may differ. Assertions in this report are based on peer-reviewed scientific research from trusted medical and governmental sources and we provide citations where possible to trace our claims back to these sources.
                </p>
                <p className="block-description">
                    <b>
                    This document is meant to start a conversation about cardiovascular disease prevention, which you should feel empowered to continue with your doctor. When in doubt, seek expert medical guidance.
                    </b>
                </p>
            </div>
        </section>
    )
}

export default DisclaimerSection