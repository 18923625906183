import React, { useState } from 'react'

function SourceBtnWithDetail(props) {
    const [showDetail, setShowDetail] = useState(false);
    const toggleDetailTextVisibility = () => {
        setShowDetail(!showDetail);
    }
    return (
        <>
            <span className='text-green-only show-hide-source' onClick={toggleDetailTextVisibility}>{showDetail ? "Hide Source": "Source"}</span>
            {showDetail && <p><small><b>{props?.detailText}</b></small></p>}
        </>
    )
}

export default SourceBtnWithDetail