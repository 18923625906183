import React, { useEffect, useState } from "react";
import { Button, Form, Input, Typography, Divider } from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  checkEmail,
  maxLength,
  minLength,
  required,
  whiteSpace,
} from "../../utils/antdFormValidtion";
import { pageTitles } from "../../constants/appDefaults";
import axiosHelper from "../../utils/axiosHelper";
import { API_ENDPOINTS } from "../../constants/apiUrl";
import { login } from "../../redux/slices/auth";
import { auth } from "../../config/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { notify } from "../../utils/common";
import { useDispatch } from "react-redux";

const { Title, Paragraph, Text } = Typography;

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  useEffect(() => {
    document.title = pageTitles.appHomePage;
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase()); // Convert email to lowercase
    // TODO: Add unit test for handleEmailChange function
  };

  // const onEmailSubmit = async () => {
  //     navigate(`/login?email=${email}`);
  // };

  const getLoggedInUserInfo = async () => {
    try {
      const response = await axiosHelper.post(
        API_ENDPOINTS.getUserDetailsByEmail,
        { email }
      );
      console.log(response);
      dispatch(
        login({
          isAuthenticated: true,
          user: {
            email: email,
            firstName: response.data.first_name,
            lastName: response.data.last_name,
            orders: response.data.user_orders,
            emailVerified: true,
          },
        })
      );

      return response;
    } catch (error) {
      console.log("error: ", error);
      throw error;
    }
  };

  useEffect(() => {
    document.title = pageTitles.login;
  }, []);

  const onEmailSubmit = async ({ password }) => {
    try {
      const loginResponse = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      let isEmailVerified = loginResponse.user.emailVerified;
      // Fetch user information
      const userInfoResponse = await getLoggedInUserInfo();

      notify.success("Logged in successfully");
      console.log("isEmailVerified: ", isEmailVerified);

      if (isEmailVerified) {
        // Pass userInfoResponse.data to the dashboard
        navigate("/dashboard", { state: userInfoResponse.data });
      } else {
        navigate("/verification-pending");
      }
    } catch (error) {
      // Handle login error
      notify.error("Invalid login credentials.");
    }
  };

  const goToSignUp = () => {
    navigate("/signup");
  };

  return (
    <React.Fragment>
      <div className="login-container">
        <Title className="section-title">Heart Health Report</Title>
        <div className="white-box">
          <div className="white-box-content">
            <div className="block-title-box">
              <Title level={3} className="block-title m-0 align-center">
                Enter registered email!
              </Title>
              <Paragraph className="block-subtitle m-0 align-center">
                Enter the email you used at the time of testing
              </Paragraph>
            </div>
            <Form
              name="basic"
              onFinish={onEmailSubmit}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item name="email" rules={[required(), checkEmail()]}>
                <Input
                  placeholder="Registered email"
                  size="large"
                  value={email}
                  onChange={handleEmailChange}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  required(),
                  minLength(8),
                  maxLength(15),
                  whiteSpace(),
                  () => ({
                    pattern: /[!@#$%^&*()\-_=+{};:,<.>]/,
                    message:
                      "Password must contain at least one special character.",
                  }),
                ]}
              >
                <Input.Password placeholder="Create password" size="large" />
              </Form.Item>
              <Form.Item>
                <Text>
                  <Link style={{ float: "right" }} to="/forgot-password">
                    Forgot password
                  </Link>
                </Text>
              </Form.Item>
              <div className="button-section align-center">
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="login-btn"
                >
                  Login
                </Button>
              </div>
              <div className="login-info-text">
                By signing up you agree to common's{" "}
                <Link
                  to="https://www.endless.health/terms-and-conditions"
                  target="_blank"
                  className="strong"
                >
                  terms & conditions
                </Link>{" "}
                and{" "}
                <Link
                  to="https://endless.health/privacy-policy"
                  target="_blank"
                  className="strong"
                >
                  privacy policy
                </Link>
              </div>
            </Form>
            <Divider />
            <div className="block-title-box">
              <Title level={4} className="block-title align-center">
                Don't have an account?
              </Title>
              <div className="button-section align-center">
                <Button type="primary" size="large" onClick={goToSignUp}>
                  Signup
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-info">
        <p className="in-case-of-any-issue text-large-20">
          <span>In case of any issue please contact us at </span>
          <a href="mailto:help@endless.health" className="strong">
            help@endless.health
          </a>
        </p>
      </div>
    </React.Fragment>
  );
};

export default Home;
