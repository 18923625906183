import { ConfigProvider } from 'antd';
import { Route, Routes } from 'react-router-dom'
import SignUp from './pages/Auth/SignUp';
import Home from './pages/Auth';
import Verify from './pages/Auth/Verify';
import VerificationDone from './pages/Auth/VerificationDone';
import VerificationPending from './pages/Auth/VerificationPending';
import ForgotPassword from './pages/Auth/ForgotPassword';
import Register from './pages/Auth/Register';
import RegisterDone from './pages/Auth/RegisterDone';
import Dashboard from './pages/Dashboard';
import PublicLayout from './components/layouts/PublicLayout';
import './App.css';

import theme from "./theme/themeConfig";
import ProtectedLayout from './components/layouts/ProtectedLayout';
import PrivateRoutes from './components/common/PrivateRoutes';
import PublicRoutes from './components/common/PublicRoutes';

function App() {

  return (
    <ConfigProvider theme={theme}>
      <div className="App">
        <Routes>
          <Route path="/" element={<PublicRoutes />} >
            <Route exact path="/" element={<PublicLayout showHeader={true}><Home /></PublicLayout>} />
            <Route exact path='/signup' element={<PublicLayout showHeader={true}><SignUp /></PublicLayout>} />
            <Route exact path='/register' element={<PublicLayout showHeader={true}><Register /></PublicLayout>} />
            <Route exact path='/events/:id' element={<PublicLayout showHeader={true}><RegisterDone /></PublicLayout>} />
            <Route exact path='/forgot-password' element={<PublicLayout showHeader={true}><ForgotPassword /></PublicLayout>} />
          </Route>
          <Route path="/" element={<PrivateRoutes />}>
            <Route exact path='/verify' element={<PublicLayout showHeader={true}><Verify /></PublicLayout>} />
            <Route exact path='/verification-done' element={<PublicLayout showHeader={true}><VerificationDone /></PublicLayout>} />
            <Route exact path='/verification-pending' element={<PublicLayout showHeader={true}><VerificationPending /></PublicLayout>} />
            <Route exact path='/dashboard' element={<ProtectedLayout showHeader={true}><Dashboard /></ProtectedLayout>} />
          </Route>
        </Routes>
      </div>
    </ConfigProvider>

  );


}

export default App;