
export const publicPageUrls = ["/","/login", "/signup","/verify"]

export const pageTitles = {
    appHomePage: "Submit Email | Endless Health",
    signup: "Signup | Endless Health",
    login: "Login | Endless Health",
    verify: "Verify your email | Endless Health",
    register: "Register | Endless Health",
    verificationPending: "Verificaton Pending | Endless Health",
    verificationDone: "Verificaton Done| Endless Health",
    dashboard: "Dashboard | Endless Health",
    forgotPassword: "Forgot Password | Endless Health"
}
