import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

const PublicRoutes = () => {
    const currentAuthUser = useSelector(store => store.auth);

    return (
        currentAuthUser?.isAuthenticated  ? <Navigate to='/dashboard' /> : <Outlet />
    )
}
export default PublicRoutes;